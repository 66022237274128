import React from 'react';

import { constants } from '@armis/armis-ui-library';
import { Box, Typography } from '@mui/material';

export const ReviewPageRow = ({
    labelName,
    fieldValue,
    index
}: {
    labelName: string;
    fieldValue: string;
    index: number;
}) => (
    <Box
        key={index}
        sx={{
            display: 'flex'
        }}
    >
        <Typography
            sx={{
                fontFamily: constants.PROXIMANOVA_W08_SEMIBOLD,
                marginRight: '3px'
            }}
            variant="h5"
        >
            {labelName}:
        </Typography>
        <Typography sx={{ wordBreak: 'break-word' }} variant="h5">
            {fieldValue}
        </Typography>
    </Box>
);
