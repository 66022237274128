import React, { useEffect, useMemo, useState, useTransition } from 'react';

import { AxiosError, AxiosResponse } from 'axios';
import { cloneDeep } from 'lodash';
import { TOAST_ID } from 'src/constants/APIConstants';
import { PARTNER_TENANTS_API_MAPPING } from 'src/constants/APIResponse';
import { PROPERTY_FIELD_TYPE_MAP } from 'src/constants/CommonConstants';
import {
    ADD,
    ASSOCIATE_TENANT,
    EDIT,
    EDIT_TENANT,
    PLACEHOLDER_PASSWORD,
    SECRET_KEY,
    SUBMIT,
    TENANT_ADD_SUCCESS,
    TENANT_EDIT_SUCCESS,
    TENANT_NAME,
    TENANT_URL,
    USERNAME
} from 'src/constants/LabelText';
import {
    displayErrorMessage,
    onFieldChangeHandler,
    showToast,
    TOAST_TYPE,
    urlFilter,
    validateFormFields
} from 'src/helpers/utility';
import { Validators } from 'src/helpers/Validators';
import { Modal } from 'src/pages/components/Modal';
import { ModalField } from 'src/pages/components/ModalField/ModalField';
import {
    StyledContainer,
    StyledSection
} from 'src/pages/containers/Partners/Partners.style';
import { AssociateTenantProps } from 'src/pages/containers/PartnerTenants/AssociateTenant/AssociateTenant.types';
import { editPartnerTenant, postPartnerTenant } from 'src/services/api.service';
import { ErrorResponse, PartnerTenantsType } from 'src/types/APIResponseTypes';
import { FieldType, ModalFieldType } from 'src/types/CommonTypes';

const initialData = {
    [TENANT_NAME]: {
        value: '',
        error: false,
        helperText: '',
        disable: false
    },
    [TENANT_URL]: {
        value: '',
        error: false,
        helperText: '',
        disable: false
    },
    [USERNAME]: {
        value: '',
        error: false,
        helperText: '',
        disable: false,
        optional: true
    },
    [SECRET_KEY]: {
        value: '',
        error: false,
        helperText: '',
        disable: false,
        optional: true
    }
};

export const AssociateTenant = ({
    currentMenuItem,
    setIsLoading,
    setModalOpen,
    modelOpen,
    getAllTenants,
    modalMode,
    setModalMode,
    partnerDetails
}: AssociateTenantProps): JSX.Element => {
    const [modalFields, setModalFields] = useState<ModalFieldType>(initialData);
    const [, startTransition] = useTransition();

    const actionFormMetaData: FieldType[][] = useMemo(
        () => [
            [
                {
                    type: PROPERTY_FIELD_TYPE_MAP.TEXT,
                    labelName: TENANT_NAME,
                    validations: {
                        required: true,
                        maxLength: 100
                    }
                },
                {
                    type: PROPERTY_FIELD_TYPE_MAP.TEXT,
                    labelName: TENANT_URL,
                    validations: {
                        required: true,
                        maxLength: 500,
                        validUrl: true
                    }
                },
                {
                    type: PROPERTY_FIELD_TYPE_MAP.TEXT,
                    labelName: USERNAME,
                    validations: {
                        maxLength: 100
                    }
                },
                {
                    type: PROPERTY_FIELD_TYPE_MAP.TEXT,
                    labelName: SECRET_KEY,
                    inputType: PROPERTY_FIELD_TYPE_MAP.PASSWORD,
                    validations: {
                        maxLength: 500
                    },
                    placeHolder: modalMode === EDIT ? PLACEHOLDER_PASSWORD : ''
                }
            ]
        ],
        [modalMode]
    );
    const editModalAction = () => {
        const clonedModalFields = cloneDeep(modalFields);
        Object.keys(clonedModalFields!).forEach(element => {
            const apiKey = PARTNER_TENANTS_API_MAPPING[element];
            const valueOfElement: string | number =
                currentMenuItem![apiKey as keyof typeof currentMenuItem];
            clonedModalFields[element].value = valueOfElement ?? '';
        });
        setModalFields({ ...clonedModalFields });
    };

    const resetData = () => {
        setModalMode(ADD);
        setModalOpen(false);
        setModalFields(initialData);
    };

    useEffect(() => {
        if (modalMode === EDIT) {
            editModalAction();
            setModalOpen(true);
        }
    }, [modalMode]);

    const submitButtonClick = () => {
        if (
            !validateFormFields(
                0,
                setModalFields,
                modalFields,
                actionFormMetaData
            )
        ) {
            const keysObject = Object.keys(modalFields);
            const dataObject = keysObject.reduce(
                (totalObject: any, currentValue) => {
                    if (
                        modalMode === ADD ||
                        currentValue === USERNAME ||
                        Validators.validateNotEmpty(
                            modalFields[currentValue].value as string
                        )
                    ) {
                        let modalFieldValue = modalFields[currentValue].value;
                        if (currentValue === TENANT_URL) {
                            modalFieldValue = urlFilter(
                                modalFieldValue as string
                            );
                        }
                        totalObject[PARTNER_TENANTS_API_MAPPING[currentValue]] =
                            modalFieldValue;
                    }

                    return totalObject;
                },
                {}
            );
            dataObject.partner = { id: partnerDetails?.id };
            setIsLoading(true);
            if (modalMode === EDIT) {
                editPartnerTenant(currentMenuItem!.id, dataObject)
                    .then((res: AxiosResponse<PartnerTenantsType>) => {
                        getAllTenants();
                        const successMsg = TENANT_EDIT_SUCCESS.replace(
                            '%s',
                            res.data.name
                        );
                        showToast(successMsg, TOAST_TYPE.SUCCESS, TOAST_ID);
                        resetData();
                    })
                    .catch((err: AxiosError<ErrorResponse>) => {
                        displayErrorMessage(err);
                    })
                    .finally(() => {
                        setIsLoading(false);
                    });
            } else {
                postPartnerTenant(dataObject)
                    .then((res: AxiosResponse<PartnerTenantsType>) => {
                        getAllTenants();
                        const successMsg = TENANT_ADD_SUCCESS.replace(
                            '%s',
                            res.data.name
                        );
                        showToast(successMsg, TOAST_TYPE.SUCCESS, TOAST_ID);
                        resetData();
                    })
                    .catch((err: AxiosError<ErrorResponse>) => {
                        displayErrorMessage(err);
                    })
                    .finally(() => {
                        setIsLoading(false);
                    });
            }
        }
    };

    return (
        <Modal
            className="wide"
            displayBtn="all"
            isModalOpen={modelOpen}
            onCancel={() => {
                resetData();
            }}
            onSubmit={submitButtonClick}
            submitBtnLabel={SUBMIT}
            title={modalMode === ADD ? ASSOCIATE_TENANT : EDIT_TENANT}
        >
            <StyledContainer minHeight={326}>
                <StyledSection noBorder>
                    {actionFormMetaData[0].map(
                        (
                            { labelName, type, inputType, placeHolder },
                            index
                        ) => (
                            <ModalField
                                key={index}
                                index={index}
                                inputType={inputType}
                                {...modalFields[labelName]}
                                labelName={labelName}
                                onChange={fieldValue =>
                                    onFieldChangeHandler(
                                        fieldValue,
                                        labelName,
                                        type,
                                        actionFormMetaData,
                                        setModalFields,
                                        0,
                                        modalFields,
                                        startTransition
                                    )
                                }
                                placeHolder={placeHolder}
                                type={type}
                            />
                        )
                    )}
                </StyledSection>
            </StyledContainer>
        </Modal>
    );
};
