import { PROPERTY_FIELD_TYPE_MAP } from 'src/constants/CommonConstants';
import { QUERY_TITLE, QUERY_VALUE } from 'src/constants/LabelText';
import { FieldType, Map } from 'src/types/CommonTypes';

export const actionFormMetaData: FieldType[][] = [
    [
        {
            type: PROPERTY_FIELD_TYPE_MAP.TEXT,
            labelName: QUERY_TITLE,
            validations: {
                required: true,
                maxLength: 500
            }
        },
        {
            type: PROPERTY_FIELD_TYPE_MAP.TEXT,
            labelName: QUERY_VALUE,
            validations: {
                required: true,
                maxLength: 500
            }
        }
    ]
];

export const initialData = {
    [QUERY_TITLE]: {
        value: '',
        error: false,
        helperText: '',
        disable: false
    },
    [QUERY_VALUE]: {
        value: '',
        error: false,
        helperText: '',
        disable: false
    }
};

export const ASQ_QUERY_MAPPING: Map<string> = {
    [QUERY_TITLE]: 'title',
    [QUERY_VALUE]: 'asq'
};
