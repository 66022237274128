import { connect } from 'react-redux';
import { RootState } from 'src/store/store';
import { User } from 'src/types/APIResponseTypes';

interface ProtectedActionProps {
    children: JSX.Element;
    hasPermissions?: string[];
    hasAnyPermission?: string[];
    resource: string;
    currentUser: User;
}

const ProtectedActionComponent = ({
    hasPermissions,
    hasAnyPermission,
    resource,
    currentUser,
    children
}: ProtectedActionProps) => {
    const findResource = currentUser.resources.find(r => r.name === resource);
    let allowAccess = false;
    if (
        hasPermissions &&
        hasPermissions.every(permission =>
            findResource?.privilegeNames.includes(permission)
        )
    ) {
        allowAccess = true;
    }

    if (
        hasAnyPermission &&
        hasAnyPermission.some(permission =>
            findResource?.privilegeNames.includes(permission)
        )
    ) {
        allowAccess = true;
    }

    return allowAccess ? children : null;
};

const mapStateToProps = (state: RootState) => ({
    currentUser: state.user.userData as User
});

export const ProtectedAction = connect(mapStateToProps)(
    ProtectedActionComponent
);
