import { TextBox, constants } from '@armis/armis-ui-library';
import { styled } from '@mui/material';

export const StyledTextBox = styled(TextBox)`
    width: 100%;

    & fieldset {
        border: none;
    }

    & .custom-helpertext {
        font-size: 10px;
        position: absolute;
        top: 21px;
    }

    & .Mui-focused:not(.invalid) {
        border-color: #8338ec !important;
    }

    & .MuiInputBase-root {
        color: ${props =>
            props.theme.palette.mode === 'dark' ? '#FCFCFC' : '#121212'};
    }
`;

export const RoleManagementContainer = styled('div')`
    display: flex;
    flex-direction: column;
    flex: 1 1 0%;
    height: 100%;
    box-sizing: content-box;
    overflow: auto;
`;

export const StyledContentHeader = styled('div')`
    display: flex;
    align-items: center;
    padding: 24px;

    & .title {
        align-items: flex-end;

        .large {
            font-size: 23px;
            line-height: 18px;
            color: ${props =>
                props.theme.palette.mode === 'dark'
                    ? constants.COLOR_GENERAL_25
                    : constants.COLOR_GENERAL_22};
        }

        .selected-count {
            margin-left: 10px;
            font-size: 14px;
            color: ${props =>
                props.theme.palette.mode === 'dark'
                    ? constants.COLOR_GENERAL_52
                    : constants.COLOR_GENERAL_28};
        }
    }
`;

export const PredefinedRoleContainer = styled('div')`
    flex: 1;
`;

export const ShowMorePredefinedRoles = styled('div')`
    transition: all 0.4s;
    overflow: hidden;
    position: relative;
`;

export const ShowMoreFooter = styled('div')`
    box-sizing: border-box;
    padding: 0px 24px;
    cursor: pointer;

    & .action {
        font-size: 12px;
        color: #64acfa;
        padding-right: 7px;
    }

    & .arrow {
        height: 8px;
        justify-self: center;
        transition: all 0.4s;
        fill: #64acfa;
        overflow: hidden;
        position: relative;
    }

    & .arrow.open {
        transform: rotate(180deg);
    }
`;

export const RolesContainer = styled('div')`
    box-sizing: border-box;
    padding: 0px 24px;
`;

export const AllRolesContainer = styled('div')`
    overflow-y: auto;
    max-height: 100%;
    margin: 0 40px;
`;

export const StyledListItem = styled('li')`
    margin-bottom: 4px;
    display: block;

    &::before {
        content: '▪';
        color: rgb(100, 172, 250);
        font-weight: bold;
        display: inline-block;
        width: 1em;
    }
`;

export const StyledDiv = styled('div')`
    display: flex;
    flex-direction: row;
    align-items: center;

    .text {
        word-break: break-word;
        margin-left: 30px;
        white-space: pre-line;
        font-size: 14px;
    }
`;
