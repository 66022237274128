import React, { useMemo, useState } from 'react';

import { Search } from '@armis/armis-ui-library';
import { InputAdornment } from '@mui/material';
import { debounce } from 'lodash';
import { IoIosClose } from 'react-icons/io';

import { SearchBarIconContainer, StyledTextBox } from './SearchBar.style';
import { SearchBarProps } from './SearchBar.types';

const SearchBar = ({ searchValue, onChange, placeholder }: SearchBarProps) => {
    const [input, setInput] = useState(searchValue);

    const debouncedSearch = useMemo(
        () =>
            debounce((newValue: string) => {
                onChange(newValue);
            }, 500),
        [onChange]
    );

    const inpChangeHandler = (updatedValue: string) => {
        setInput(updatedValue);

        const trimmedSearch = updatedValue.trim();
        if (trimmedSearch.length || updatedValue === '')
            debouncedSearch(trimmedSearch);
    };

    return (
        <StyledTextBox
            InputProps={{
                endAdornment: (
                    <SearchBarIconContainer>
                        <InputAdornment
                            className="clear"
                            onClick={() => {
                                onChange('');
                                setInput('');
                            }}
                            position="end"
                            variant="outlined"
                        >
                            {input !== '' && <IoIosClose />}
                        </InputAdornment>
                    </SearchBarIconContainer>
                ),
                startAdornment: (
                    <SearchBarIconContainer>
                        <InputAdornment position="end" variant="outlined">
                            <Search />
                        </InputAdornment>
                    </SearchBarIconContainer>
                )
            }}
            onChange={e => inpChangeHandler(e.target.value)}
            placeholder={placeholder}
            value={input}
            variant="outlined"
        />
    );
};

export default SearchBar;
