import { useState, useRef, MouseEvent, useEffect } from 'react';

import { CHECKBOX_STATUS, MenuItemProps } from '@armis/armis-ui-library';
import { Column } from 'ag-grid-community';
import { AgGridReact as AgGridReactType } from 'ag-grid-react';
import { cloneDeep } from 'lodash';
import { DEFAULT_PAGE, DEFAULT_PAGESIZE } from 'src/constants/TableConstants';
import { getDirectionsAndProperties } from 'src/helpers/utility';
import { Map, FilterItems } from 'src/types/CommonTypes';

type TableHookProps = {
    sortOrderObj: Map<number>;
    sortStatusObj: Map<string>;
    columnsFilterItems: FilterItems[];
    columnFilterObj?: { [key: string]: FilterItems[] };
    queryPropertiesObj?: { [key: string]: string };
};

export const useTable = ({
    sortOrderObj,
    sortStatusObj,
    columnsFilterItems,
    columnFilterObj = {},
    queryPropertiesObj = {}
}: TableHookProps) => {
    const [modelOpen, setModalOpen] = useState(false);
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const [filterItems, setFilterItems] = useState(columnsFilterItems);
    const gridRef = useRef<AgGridReactType>(null);
    const [tableLoading, setTableLoading] = useState(false);
    const [columnSortOrder, setColumnSortOrder] = useState(sortOrderObj);
    const [columnSortStatus, setColumnSortStatus] = useState(sortStatusObj);
    const [columnFilterData, setColumnFilterData] = useState<{
        [key: string]: FilterItems[];
    }>(columnFilterObj);
    const [queryProperties, setQueryProperties] = useState({
        searchBy: '',
        page: DEFAULT_PAGE,
        size: DEFAULT_PAGESIZE,
        directions: '',
        properties: '',
        ...queryPropertiesObj
    });
    const onFilterChanged = (
        columnName: string,
        selectedItems: FilterItems[],
        filterDates?: any,
        filterType?: string
    ) => {
        if (filterType === 'date') {
            setQueryProperties(prevValue => ({
                ...prevValue,
                filterByStartDate: filterDates?.filterByStartDate || '',
                filterByEndDate: filterDates?.filterByEndDate || ''
            }));
            return;
        }

        const { directions, properties } = getDirectionsAndProperties(
            columnSortOrder,
            columnSortStatus
        );
        setQueryProperties(prevValue => ({
            ...prevValue,
            [columnName]:
                selectedItems.length === columnFilterData[columnName].length
                    ? ''
                    : selectedItems.map(item => item.label).join(','),
            directions,
            properties,
            page: DEFAULT_PAGE,
            size: DEFAULT_PAGESIZE
        }));
        const updatedFilter = cloneDeep(columnFilterData[columnName]);
        updatedFilter.forEach(({ id }, index) => {
            if (selectedItems.find(item => item.id === id)) {
                updatedFilter[index].checkStatus = CHECKBOX_STATUS.CHECKED;
            } else {
                updatedFilter[index].checkStatus = CHECKBOX_STATUS.UNCHECKED;
            }
        });
        setColumnFilterData(prevValue => ({
            ...prevValue,
            [columnName]: updatedFilter
        }));
    };

    const onSortChangedCall = (
        columnName: string,
        sortOrder: string,
        columnSortArray: Map<number>
    ) => {
        setColumnSortStatus({
            ...columnSortStatus,
            [columnName]: sortOrder
        });
        if (
            JSON.stringify(columnSortArray) !== JSON.stringify(columnSortOrder)
        ) {
            setColumnSortOrder(columnSortArray);
        }
    };

    useEffect(() => {
        if (tableLoading) {
            gridRef.current?.api?.showLoadingOverlay();
        } else {
            gridRef.current?.api?.hideOverlay();
        }
    }, [tableLoading]);

    const handleMenuClick = (event: MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleMenuClose = () => {
        const updatedFilterItems = cloneDeep(filterItems);
        (gridRef.current?.columnApi?.getColumns() ?? []).forEach(
            (element: Column) => {
                const item = updatedFilterItems.find(
                    ({ id }) => id === element.getColId()
                );
                if (item)
                    item.checkStatus = element.isVisible()
                        ? CHECKBOX_STATUS.CHECKED
                        : CHECKBOX_STATUS.UNCHECKED;
            }
        );
        setFilterItems(updatedFilterItems);
        setAnchorEl(null);
    };

    const onSelectionChanged = (selectedItems: MenuItemProps[]) => {
        const columnsId = selectedItems.map(element => ({
            key: element.id,
            value: true
        }));

        filterItems.forEach((element: FilterItems) => {
            if (!selectedItems.find(({ id }) => id === element.id)) {
                columnsId.push({
                    key: element.id,
                    value: false
                });
            }
        });
        columnsId.forEach(element => {
            gridRef.current?.columnApi?.setColumnVisible(
                element.key,
                element.value
            );
        });
    };

    return {
        tableLoading,
        setTableLoading,
        columnSortOrder,
        columnSortStatus,
        gridRef,
        filterItems,
        setFilterItems,
        onSortChangedCall,
        handleMenuClick,
        handleMenuClose,
        modelOpen,
        setModalOpen,
        anchorEl,
        setAnchorEl,
        onSelectionChanged,
        onFilterChanged,
        queryProperties,
        columnFilterData,
        setColumnFilterData,
        setQueryProperties
    };
};
