import React, { useEffect, useMemo, useRef, useState } from 'react';

import {
    Button,
    CustomHeader,
    IconActivate,
    IconButton,
    IconDeactivate,
    IconPencil,
    MenuIcon,
    Pagination,
    Table,
    TableNoData,
    TableSkeleton,
    StyledMenuPaper,
    MultiSelect,
    IconDelete,
    WhitePlus
} from '@armis/armis-ui-library';
import { Menu, PaperProps } from '@mui/material';
import { ColDef, ICellRendererParams } from 'ag-grid-community';
import { AxiosError, AxiosResponse } from 'axios';
import { useSelector } from 'react-redux';
import { TOAST_ID } from 'src/constants/APIConstants';
import { Privileges, Resources } from 'src/constants/CommonConstants';
import {
    ACTIVATE,
    ADD,
    ADD_PARTNER,
    DELETE,
    EDIT,
    INACTIVATE,
    PARTNERS,
    SEARCH_PARTNERS_PLACEHOLDER,
    PARTNER_ACTIVATE_SUCCESS,
    PARTNER_INACTIVATE_SUCCESS,
    PARTNER_DELETE_MESSAGE,
    PARTNER_DELETE_SUCCESS,
    PARTNER_DELETE_TITLE,
    ADMIN_FIRST_NAME,
    ADMIN_LAST_NAME,
    PARTNER
} from 'src/constants/LabelText';
import {
    createRelatedObject,
    DEFAULT_PAGE,
    DEFAULT_PAGESIZE,
    NO_DATA_TO_SHOW,
    partnersAPIMapping
} from 'src/constants/TableConstants';
import { partnersColumnDef } from 'src/helpers/ColumnsConfig';
import {
    convertQueryObjectToParams,
    displayErrorMessage,
    getDirectionsAndProperties,
    isActionHasPermissions,
    showToast,
    TOAST_TYPE
} from 'src/helpers/utility';
import IsLoadingHOC from 'src/hoc/IsLoadingHoc';
import { useTable } from 'src/hooks/useTable';
import { ProtectedAction } from 'src/pages/common/ProtectedAction';
import { Header } from 'src/pages/components/Header';
import { Modal } from 'src/pages/components/Modal';
import SearchBar from 'src/pages/components/SearchBar/SearchBar';
import { TableHeader } from 'src/pages/components/TableHeader';
import { WarningModalContainer } from 'src/pages/components/WarningModalContainer/WarningModalContainer';
import AddPartner from 'src/pages/containers/Partners/AddPartner/AddPartner';
import {
    StyledMenuItem,
    StyledOption
} from 'src/pages/containers/Partners/Partners.style';
import {
    PartnerRowData,
    PartnersProps
} from 'src/pages/containers/Partners/Partners.types';
import {
    activatePartner,
    deletePartner,
    getPartnersData
} from 'src/services/api.service';
import { selectUser } from 'src/store/slices/userSlice';
import {
    ErrorResponse,
    GenericResponseData,
    PartnersType
} from 'src/types/APIResponseTypes';
import {
    ChildRefProp,
    FilterItems,
    Map,
    MenuItemType
} from 'src/types/CommonTypes';

const partnerSortOrder: Map<number> = {};
const partnerSortStatus: Map<string> = {};
const columnsFilterItems: FilterItems[] = [];
const hideColumns = [ADMIN_FIRST_NAME, ADMIN_LAST_NAME];
createRelatedObject(
    partnersAPIMapping,
    partnerSortOrder,
    partnerSortStatus,
    columnsFilterItems,
    hideColumns
);

const PartnersComponent = ({ setIsLoading }: PartnersProps) => {
    const {
        tableLoading,
        setTableLoading,
        columnSortOrder,
        columnSortStatus,
        gridRef,
        filterItems,
        onSortChangedCall,
        handleMenuClick,
        handleMenuClose,
        modelOpen,
        setModalOpen,
        anchorEl,
        onSelectionChanged,
        queryProperties,
        setQueryProperties
    } = useTable({
        sortOrderObj: partnerSortOrder,
        sortStatusObj: partnerSortStatus,
        columnsFilterItems
    });
    const [modalMode, setModalMode] = useState(ADD);
    const paginationRef = useRef<ChildRefProp>();
    const firstRender = useRef(true);
    const [rowData, setRowData] = useState<PartnerRowData[]>([]);
    const [totalRows, setTotalRows] = useState<number>(0);
    const [anchorElAction, setAnchorElAction] = useState<null | HTMLElement>(
        null
    );
    const [currentMenuItem, setCurrentMenuItem] = useState<PartnersType | null>(
        null
    );

    const currentUser = useSelector(selectUser);

    const menuActionsObject: MenuItemType[] = useMemo(
        () => [
            {
                name: EDIT,
                privileges: [Privileges.edit],
                icon: <IconPencil height={16} />
            },
            {
                name: DELETE,
                privileges: [Privileges.delete],
                icon: (
                    <IconDelete className="svg-symbol icon-delete medium theme-5" />
                )
            },
            {
                name: INACTIVATE,
                icon: <IconDeactivate />,
                privileges: [Privileges.edit],
                disabled: !currentMenuItem?.active
            },
            {
                name: ACTIVATE,
                icon: <IconActivate />,
                privileges: [Privileges.edit],
                disabled: currentMenuItem?.active
            }
        ],
        [currentMenuItem]
    );

    const [warningModal, setWarningModal] = useState(false);
    const defaultColDefs = useMemo(
        () => ({
            headerComponent: CustomHeader,
            headerComponentParams: {
                onSortChanged: onSortChangedCall,
                columnSortOrder,
                columnSortStatus
            },
            sortable: true,
            resizable: true
        }),
        [columnSortOrder, columnSortStatus]
    );
    useEffect(() => {
        if (!gridRef.current?.api) return;
        if (rowData.length === 0 && !tableLoading && !firstRender.current) {
            setTimeout(() => {
                gridRef.current?.api.showNoRowsOverlay();
            }, 100);
        }
    }, [rowData, tableLoading, gridRef]);

    const getAllPartners = () => {
        setTableLoading(true);
        getPartnersData(convertQueryObjectToParams(queryProperties))
            .then((res: AxiosResponse<GenericResponseData<PartnersType>>) => {
                setTotalRows(res.data.totalElements);
                const rowDataArray = res.data.content.map(element => {
                    const { regionDto, ...rest } = element;
                    return {
                        ...rest,
                        regionDto: regionDto.name,
                        partnerURL: regionDto.url,
                        regionId: regionDto.id
                    };
                });
                setRowData(rowDataArray);
            })
            .catch((err: AxiosError<ErrorResponse>) => {
                displayErrorMessage(err);
                setRowData([]);
                setTotalRows(0);
            })
            .finally(() => {
                setTableLoading(false);
                if (
                    queryProperties.page === DEFAULT_PAGE &&
                    queryProperties.size === DEFAULT_PAGESIZE
                )
                    paginationRef.current?.resetPagination();
            });
    };

    useEffect(() => {
        getAllPartners();
    }, [queryProperties]);

    useEffect(() => {
        if (firstRender.current) {
            firstRender.current = false;
            return;
        }
        const { directions, properties } = getDirectionsAndProperties(
            columnSortOrder,
            columnSortStatus
        );
        setQueryProperties(prevValue => ({
            ...prevValue,
            directions,
            properties,
            page: DEFAULT_PAGE,
            size: DEFAULT_PAGESIZE
        }));
    }, [columnSortOrder, columnSortStatus]);

    const activatePartnerFunction = (activate: boolean) => {
        setIsLoading(true);
        activatePartner(currentMenuItem!.id, activate)
            .then(() => {
                getAllPartners();
                const successMsg = (
                    activate
                        ? PARTNER_ACTIVATE_SUCCESS
                        : PARTNER_INACTIVATE_SUCCESS
                ).replace('%s', currentMenuItem!.name);
                showToast(successMsg, TOAST_TYPE.SUCCESS, TOAST_ID);
            })
            .catch((err: AxiosError<ErrorResponse>) => {
                displayErrorMessage(err);
            })
            .finally(() => {
                setIsLoading(false);
            });
    };

    const doActionForMenuClick = (name: string) => {
        switch (name) {
            case ACTIVATE:
                activatePartnerFunction(true);
                break;
            case INACTIVATE:
                activatePartnerFunction(false);
                break;
            case DELETE:
                setWarningModal(true);
                break;
            case EDIT:
                setModalMode(EDIT);
                break;
            default:
                break;
        }
        setAnchorElAction(null);
    };

    const deletePartnerAction = () => {
        setIsLoading(true);
        deletePartner(currentMenuItem!.id)
            .then(() => {
                getAllPartners();
                showToast(
                    PARTNER_DELETE_SUCCESS.replace('%s', currentMenuItem!.name),
                    TOAST_TYPE.SUCCESS,
                    TOAST_ID
                );
            })
            .catch((err: AxiosError<ErrorResponse>) => {
                displayErrorMessage(err);
            })
            .finally(() => {
                setIsLoading(false);
            });
    };
    const columnConfig = useMemo(
        () => [
            ...partnersColumnDef,
            {
                field: 'partnerActions',
                headerName: 'Actions',
                pinned: 'right',
                suppressMovable: true,
                // eslint-disable-next-line react/no-unstable-nested-components
                cellRenderer: (params: ICellRendererParams<PartnersType>) => (
                    <IconButton
                        className="Icon-Hover-Effect"
                        data-testid="partner-actions"
                        disabled={
                            !isActionHasPermissions(
                                currentUser,
                                Resources.partner,
                                [Privileges.edit, Privileges.delete]
                            )
                        }
                        onClick={event => {
                            setCurrentMenuItem(params.data!);
                            setAnchorElAction(event.currentTarget);
                        }}
                    >
                        <MenuIcon />
                    </IconButton>
                ),
                sortable: false
            } as ColDef
        ],
        []
    );

    const gridReady = () => {
        hideColumns.forEach(element =>
            gridRef.current?.columnApi?.setColumnVisible(element, false)
        );
    };

    return (
        <>
            <Header title={PARTNERS}>
                <ProtectedAction
                    hasPermissions={[Privileges.create]}
                    resource={Resources.partner}
                >
                    <Button
                        className="header-add-button"
                        color="primary"
                        disabled={tableLoading}
                        onClick={() => setModalOpen(true)}
                        startIcon={<WhitePlus />}
                        variant="contained"
                    >
                        {ADD_PARTNER}
                    </Button>
                </ProtectedAction>
            </Header>
            <div className="control table">
                <TableHeader
                    childrenLeft={
                        <SearchBar
                            onChange={newValue => {
                                setQueryProperties(prevValue => ({
                                    ...prevValue,
                                    searchBy: newValue,
                                    page: DEFAULT_PAGE,
                                    size: DEFAULT_PAGESIZE
                                }));
                            }}
                            placeholder={SEARCH_PARTNERS_PLACEHOLDER}
                            searchValue={queryProperties.searchBy}
                        />
                    }
                    loading={tableLoading}
                    onColumnMenuClick={handleMenuClick}
                    title={`${totalRows} ${
                        totalRows === 1 ? PARTNER : PARTNERS
                    }`}
                />
                <Menu
                    anchorEl={anchorEl}
                    onClose={handleMenuClose}
                    open={Boolean(anchorEl)}
                    PaperProps={
                        {
                            component: StyledMenuPaper
                        } as Partial<PaperProps<'div', {}>> | undefined
                    }
                >
                    <MultiSelect
                        items={filterItems}
                        onSelectionChanged={onSelectionChanged}
                        showSelectAllOption
                    />
                </Menu>
                <Modal
                    displayBtn="all"
                    isModalOpen={warningModal}
                    onCancel={() => setWarningModal(false)}
                    onSubmit={() => {
                        setWarningModal(false);
                        deletePartnerAction();
                    }}
                    submitBtnLabel={DELETE}
                    title={PARTNER_DELETE_TITLE.replace(
                        '%s',
                        currentMenuItem?.name!
                    )}
                >
                    <WarningModalContainer
                        text={PARTNER_DELETE_MESSAGE.replace(
                            '%s',
                            currentMenuItem?.name!
                        )}
                    />
                </Modal>
                <Menu
                    anchorEl={anchorElAction}
                    onClose={() => {
                        setAnchorElAction(null);
                    }}
                    open={Boolean(anchorElAction)}
                    PaperProps={
                        {
                            component: StyledMenuPaper
                        } as Partial<PaperProps<'div', {}>> | undefined
                    }
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'right'
                    }}
                >
                    {menuActionsObject.map((element, index) => (
                        <ProtectedAction
                            key={index}
                            hasPermissions={[...element.privileges]}
                            resource={Resources.partner}
                        >
                            <StyledMenuItem
                                key={index}
                                disabled={element.disabled}
                                onClick={() =>
                                    doActionForMenuClick(element.name)
                                }
                            >
                                {element.icon}
                                <StyledOption>{element.name}</StyledOption>
                            </StyledMenuItem>
                        </ProtectedAction>
                    ))}
                </Menu>
                <AddPartner
                    currentMenuItem={currentMenuItem}
                    getAllPartners={getAllPartners}
                    modalMode={modalMode}
                    modelOpen={modelOpen}
                    setIsLoading={setIsLoading}
                    setModalMode={setModalMode}
                    setModalOpen={setModalOpen}
                />
                <Table
                    ref={gridRef}
                    columnDefs={columnConfig}
                    defaultColDef={defaultColDefs}
                    loadingOverlayComponent={TableSkeleton}
                    noRowsOverlayComponent={TableNoData}
                    noRowsOverlayComponentParams={{
                        content: NO_DATA_TO_SHOW
                    }}
                    onGridReady={gridReady}
                    rowData={rowData}
                    suppressNoRowsOverlay={firstRender.current}
                />
                {!!totalRows && totalRows > 0 && (
                    <Pagination
                        ref={paginationRef}
                        onPaginationChanged={(page, size) => {
                            setQueryProperties(prevValue => ({
                                ...prevValue,
                                page,
                                size
                            }));
                        }}
                        totalRowsCount={totalRows}
                    />
                )}
            </div>
        </>
    );
};

export const Partners = IsLoadingHOC(PartnersComponent);
