import { createSlice } from '@reduxjs/toolkit';
import type { RootState } from 'src/store/store';
import { RegionData } from 'src/types/APIResponseTypes';

interface InitialStateType {
    regions: RegionData[];
    selectedRegion: string;
}

const initialState: InitialStateType = {
    regions: [],
    selectedRegion: ''
};

export const regionSlice = createSlice({
    name: 'region',
    initialState,
    reducers: {
        setRegions: (state, action) => {
            state.regions = action.payload;
        },
        setSelectedRegion: (state, action) => {
            state.selectedRegion = action.payload;
        }
    }
});

export const selectRegions = (state: RootState) =>
    state.region.regions as RegionData[];

export const selectSelectedRegion = (state: RootState) =>
    state.region.selectedRegion as string;

// Action creators are generated for each case reducer function
export const { setRegions, setSelectedRegion } = regionSlice.actions;

export default regionSlice.reducer;
