import { useEffect } from 'react';

import { ToastPopUpContainer, getTheme } from '@armis/armis-ui-library';
import { ThemeProvider, CssBaseline } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { BrowserRouter as Router } from 'react-router-dom';
import {
    setTheme as setThemeInStore,
    selectThemeMode
} from 'src/store/slices/themeSlice';

import AppRoutes from './AppRoutes';

const App = () => {
    const dispatch = useDispatch();
    const selectedTheme = useSelector(selectThemeMode);

    useEffect(() => {
        const storedTheme = localStorage.getItem('armis-theme') || 'light';
        dispatch(setThemeInStore(storedTheme));
    }, []);

    return (
        <ThemeProvider theme={getTheme(selectedTheme)}>
            <CssBaseline />
            <>
                <ToastPopUpContainer
                    pauseOnHover={false}
                    position="top-center"
                />
                <Router>
                    <AppRoutes />
                </Router>
            </>
        </ThemeProvider>
    );
};

export default App;
