import { PROPERTY_FIELD_TYPE_MAP } from 'src/constants/CommonConstants';
import {
    FIRSTNAME,
    USER_NAME,
    LASTNAME,
    PHONE_NUMBER,
    ROLES,
    EMAIL,
    EMAIL_REGEX,
    PHONENUMBER
} from 'src/constants/LabelText';
import { PropertyValidationType } from 'src/types/CommonTypes';

export const PROPERTY_FIELD_KEY_MAP = {
    FIRSTNAME,
    LASTNAME,
    USERNAME: USER_NAME,
    PHONE_NUMBER,
    ROLES
};
export const UserFieldMetaData = {
    [PROPERTY_FIELD_KEY_MAP.FIRSTNAME]: {
        label: FIRSTNAME[0].toUpperCase() + FIRSTNAME.slice(1),
        key: FIRSTNAME,
        type: PROPERTY_FIELD_TYPE_MAP.TEXT,
        gridSize: 6,
        validations: {
            required: true,
            maxLength: 100
        } as PropertyValidationType
    },
    [PROPERTY_FIELD_KEY_MAP.LASTNAME]: {
        label: LASTNAME[0].toUpperCase() + LASTNAME.slice(1),
        key: LASTNAME,
        type: PROPERTY_FIELD_TYPE_MAP.TEXT,
        gridSize: 6,
        validations: {
            required: true,
            maxLength: 100
        } as PropertyValidationType
    },
    [PROPERTY_FIELD_KEY_MAP.USERNAME]: {
        label: EMAIL,
        key: USER_NAME,
        type: PROPERTY_FIELD_TYPE_MAP.TEXT,
        gridSize: 12,
        validations: {
            required: true,
            regex: EMAIL_REGEX,
            maxLength: 254
        } as PropertyValidationType
    },
    [PROPERTY_FIELD_KEY_MAP.PHONE_NUMBER]: {
        label: PHONENUMBER,
        key: PHONE_NUMBER,
        type: PROPERTY_FIELD_TYPE_MAP.TEXT,
        gridSize: 12,
        optional: true,
        validations: {
            maxLength: 20
        } as PropertyValidationType
    },
    [PROPERTY_FIELD_KEY_MAP.ROLES]: {
        label: ROLES[0].toUpperCase() + ROLES.slice(1),
        key: ROLES,
        type: PROPERTY_FIELD_TYPE_MAP.DROPDOWN,
        gridSize: 12,
        validations: {
            required: true
        } as PropertyValidationType
    }
};
export const ACTION_TYPE = {
    ADD: 'add' as 'add',
    EDIT: 'edit' as 'edit'
};
