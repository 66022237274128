import { constants } from '@armis/armis-ui-library';
import { styled } from '@mui/material';

export const LabelContainer = styled('td')`
    color: ${props =>
        props.theme.palette.mode === 'dark'
            ? constants.COLOR_GENERAL_26
            : constants.COLOR_GENERAL_24};
    min-width: 145px;
    padding: 10px 0;
    padding-right: 30px;
`;

export const StyledInput = styled('input')`
    color: ${props =>
        props.theme.palette.mode === 'dark'
            ? constants.COLOR_GENERAL_25
            : constants.COLOR_GENERAL_22};
    background-color: ${props =>
        props.theme.palette.mode === 'dark' ? '#3b3b3b' : '#ffffff'};
    font-size: 16px;
    border: none;
    font-family: 'Proxima Nova W08 Reg';
    ::placeholder {
        opacity: 0.5;
    }
    &:focus {
        outline: none;
    }
`;

export const ChangeTime = styled('span')`
    font-size: 10px;
    color: #767676;
`;

export const StyledImage = styled('img')`
    margin-right: 3px;
    display: inline;
    fill: ${props =>
        props.theme.palette.mode === 'dark' ? '#fff' : '#121212'};
    overflow: visible;
`;
