import React, { useState } from 'react';

import { AxiosError } from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import { TOAST_ID } from 'src/constants/APIConstants';
import {
    DONE,
    DUMMY_PASSWORD,
    EMAIL,
    FIRST_NAME,
    FIRST_NAME_VALIDATE,
    LAST_NAME,
    LAST_NAME_VALIDATE,
    MY_PROFILE,
    PASSWORD_PLACEHOLDER,
    PHONE,
    PHONE_VALIDATE,
    USERNAME
} from 'src/constants/LabelText';
import { showToast, TOAST_TYPE } from 'src/helpers/utility';
import { Validators } from 'src/helpers/Validators';
import IsLoadingHOC from 'src/hoc/IsLoadingHoc';
import { ConfirmAction } from 'src/pages/components/ConfirmAction';
import { Header } from 'src/pages/components/Header';
import { updateUser } from 'src/services/api.service';
import {
    selectUser,
    setUser as setUserInStore
} from 'src/store/slices/userSlice';
import { UpdateUserPayload } from 'src/types/APIPayloadTypes';
import { ErrorResponse, User } from 'src/types/APIResponseTypes';

import { ChangePassword } from './ChangePassword/ChangePassword';
import { EditField } from './EditField/EditField';
import {
    ProfileContainer,
    Control,
    Area,
    Content,
    ImageContainer,
    FieldsContainer,
    StyledAvatar,
    ProfileHeaderContainer
} from './Profile.style';

const EditFieldMetaData = [
    [
        {
            label: FIRST_NAME,
            key: 'firstname',
            disabled: false,
            type: 'text'
        },
        {
            label: USERNAME,
            key: 'username',
            disabled: true,
            type: 'text'
        },
        {
            label: EMAIL,
            key: 'email',
            disabled: true,
            type: 'text'
        },
        {
            label: LAST_NAME,
            key: 'lastname',
            disabled: false,
            type: 'text'
        },
        {
            label: PASSWORD_PLACEHOLDER,
            key: 'password',
            disabled: false,
            type: 'password'
        },
        {
            label: PHONE,
            key: 'phonenumber',
            disabled: false,
            type: 'tel'
        }
    ]
];
interface ProfileProps {
    setIsLoading: (
        isComponentLoading: boolean,
        needFullPageLoading?: boolean
    ) => undefined;
}

type UserKeys = keyof User;
const ProfileComponent = ({ setIsLoading }: ProfileProps) => {
    const [password, setPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [retypePassword, setRetypePassword] = useState('');

    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isConfirmActionModalOpen, setIsConfirmActionModalOpen] =
        useState(true);

    const storedUser = useSelector(selectUser);
    const dispatch = useDispatch();
    const [user, setUser] = useState(storedUser);

    const validateFields = (key: UserKeys) => {
        let valid = true;
        let errMsg = '';
        const value = user[key]?.toString().trim() as string;
        switch (key) {
            case 'firstname':
                valid =
                    Validators.validateNotEmpty(value) &&
                    Validators.validateMaxLength(value, 100);
                errMsg = FIRST_NAME_VALIDATE;
                break;
            case 'lastname':
                valid =
                    Validators.validateNotEmpty(value) &&
                    Validators.validateMaxLength(value, 100);
                errMsg = LAST_NAME_VALIDATE;
                break;
            case 'phonenumber':
                valid = Validators.validateMaxLength(value, 20);
                errMsg = PHONE_VALIDATE;
                break;
            default:
                break;
        }

        if (!valid) {
            showToast(errMsg, TOAST_TYPE.ERROR, TOAST_ID);
        }

        return valid;
    };

    const onFieldChangeHandler = (key: UserKeys) => {
        const value = user[key]?.toString().trim() as string;
        if (storedUser[key] === value) {
            return;
        }

        if (!validateFields(key)) {
            setUser(storedUser);
            return;
        }

        const data = {
            username: user.username,
            firstname: user.firstname,
            lastname: user.lastname,
            phonenumber: user.phonenumber,
            [key]: value
        };

        setIsLoading(true);

        updateUser(data as UpdateUserPayload)
            .then(res => {
                dispatch(setUserInStore(res.data));
                setUser(res.data);
                setIsLoading(false);
                showToast(DONE, TOAST_TYPE.SUCCESS, TOAST_ID);
            })
            .catch((err: AxiosError<ErrorResponse>) => {
                setUser(storedUser);
                setIsLoading(false);
                if (err.response) {
                    const fieldError = err.response?.data?.errors?.[key]!;
                    showToast(
                        fieldError
                            ? `${key}: ${fieldError}`
                            : err.response.data.message!,
                        TOAST_TYPE.ERROR,
                        TOAST_ID
                    );
                } else {
                    showToast(err.message, TOAST_TYPE.ERROR, TOAST_ID);
                }
            });
    };

    const onClickCancel = () => {
        setIsModalOpen(false);
        setIsConfirmActionModalOpen(true);

        setPassword('');
        setNewPassword('');
        setRetypePassword('');
    };

    const onSubmitSuccess = (updatedUser?: User) => {
        if (isConfirmActionModalOpen) {
            setIsLoading(false);
            setIsConfirmActionModalOpen(false);
        } else {
            dispatch(setUserInStore(updatedUser));
            onClickCancel();
            showToast(DONE, TOAST_TYPE.SUCCESS, TOAST_ID);
            setIsLoading(false);
        }
    };

    const getValue = (key: string) => {
        if (key === 'email') return user.username;
        if (key === 'password') return DUMMY_PASSWORD;
        return (user[key as UserKeys] as string) || '';
    };

    const getEditFields = (index: number) =>
        EditFieldMetaData[index].map(({ disabled, key, label, type }) => (
            <EditField
                key={key}
                disabled={key === 'password' ? storedUser?.issso : disabled}
                id={key}
                label={label}
                onChange={e => {
                    setUser(prevState => ({
                        ...prevState,
                        [key]: e.target.value
                    }));
                }}
                onFieldClick={() => setIsModalOpen(true)} // Only for password field
                onLeaveField={() => {
                    if (key !== 'password')
                        onFieldChangeHandler(key as UserKeys);
                }}
                passwordChangeData={storedUser?.lastpasswordchangeddate}
                type={type}
                value={getValue(key)}
            />
        ));

    return (
        <ProfileContainer>
            <Header title={MY_PROFILE} />
            {isModalOpen &&
                (isConfirmActionModalOpen ? (
                    <ConfirmAction
                        key="confirm-action-modal"
                        onClickCancel={onClickCancel}
                        onSubmitSuccess={() => onSubmitSuccess()}
                        password={password}
                        setIsLoading={setIsLoading}
                        setPassword={setPassword}
                    />
                ) : (
                    <ChangePassword
                        key="change-password-modal"
                        newPassword={newPassword}
                        onClickCancel={onClickCancel}
                        onSubmitSuccess={onSubmitSuccess}
                        password={password}
                        retypePassword={retypePassword}
                        setIsLoading={setIsLoading}
                        setNewPassword={setNewPassword}
                        setRetypePassword={setRetypePassword}
                    />
                ))}
            <Control>
                <Area>
                    <Content onSubmit={e => e.preventDefault()}>
                        <ImageContainer>
                            <StyledAvatar>
                                {storedUser?.firstname.charAt(0)}
                            </StyledAvatar>
                        </ImageContainer>
                        <FieldsContainer>
                            <ProfileHeaderContainer>
                                Profile
                            </ProfileHeaderContainer>
                            <tbody>{getEditFields(0)}</tbody>
                        </FieldsContainer>
                    </Content>
                </Area>
            </Control>
        </ProfileContainer>
    );
};

export const Profile = IsLoadingHOC(ProfileComponent);
