import React, { useEffect, useState, useTransition } from 'react';

import { Back, Button, TabGroup } from '@armis/armis-ui-library';
import { Box } from '@mui/material';
import { AxiosError, AxiosResponse } from 'axios';
import { cloneDeep, range } from 'lodash';
import { TOAST_ID } from 'src/constants/APIConstants';
import { PARTNER_API_MAPPING } from 'src/constants/APIResponse';
import { PROPERTY_FIELD_TYPE_MAP } from 'src/constants/CommonConstants';
import {
    ADD,
    ADD_ADMINISTRATOR,
    ADD_PARTNER,
    EDIT,
    EDIT_PARTNER,
    EMAIL_REGEX,
    NEXT,
    PARTNER_ADD_SUCCESS,
    PARTNER_DETAILS,
    PARTNER_EDIT_SUCCESS,
    PARTNER_EMAIL,
    PARTNER_FIRST_NAME,
    PARTNER_INSTANCE_REGION,
    PARTNER_INSTANCE_NOTES,
    PARTNER_LAST_NAME,
    PARTNER_NAME,
    PARTNER_PHONE_NUMBER,
    PARTNER_REVIEW,
    PARTNER_SALESFORCE_ID,
    SUBMIT,
    BACK
} from 'src/constants/LabelText';
import {
    displayErrorMessage,
    onFieldChangeHandler,
    showToast,
    TOAST_TYPE,
    validateFormFields
} from 'src/helpers/utility';
import { Modal } from 'src/pages/components/Modal';
import { ModalField } from 'src/pages/components/ModalField/ModalField';
import { AddPartnerProps } from 'src/pages/containers/Partners/AddPartner/AddPartner.types';
import {
    StyledBox,
    StyledCombinedField,
    StyledContainer,
    StyledReview,
    StyledSection
} from 'src/pages/containers/Partners/Partners.style';
import { ReviewPageRow } from 'src/pages/containers/Partners/ReviewPageRow/ReviewPageRow';
import { ReviewPageTitle } from 'src/pages/containers/Partners/ReviewPageTitle/ReviewPageTitle';
import {
    editPartner,
    getRegions,
    postFormDATA
} from 'src/services/api.service';
import {
    ErrorResponse,
    PartnersType,
    RegionData,
    RegionResponseData
} from 'src/types/APIResponseTypes';
import { FieldType, ModalFieldType } from 'src/types/CommonTypes';

const pageNames = {
    0: PARTNER_DETAILS,
    1: ADD_ADMINISTRATOR
};
const disableForEdit = [PARTNER_NAME, PARTNER_INSTANCE_REGION];
const initialData = {
    [PARTNER_NAME]: {
        value: '',
        error: false,
        helperText: '',
        disable: false
    },
    [PARTNER_SALESFORCE_ID]: {
        value: '',
        error: false,
        helperText: '',
        disable: false
    },
    [PARTNER_FIRST_NAME]: {
        value: '',
        error: false,
        helperText: '',
        disable: false
    },
    [PARTNER_LAST_NAME]: {
        value: '',
        error: false,
        helperText: '',
        disable: false
    },
    [PARTNER_EMAIL]: {
        value: '',
        error: false,
        helperText: '',
        disable: false
    },
    [PARTNER_PHONE_NUMBER]: {
        value: '',
        error: false,
        helperText: '',
        disable: false,
        optional: true
    },
    [PARTNER_INSTANCE_REGION]: {
        value: '',
        error: false,
        helperText: '',
        disable: false
    },
    [PARTNER_INSTANCE_NOTES]: {
        value: '',
        error: false,
        helperText: '',
        disable: false,
        optional: true
    }
};
const tabsItems = [
    {
        label: PARTNER_DETAILS,
        tabValue: 0
    },
    {
        label: ADD_ADMINISTRATOR,
        tabValue: 1
    },
    {
        label: PARTNER_REVIEW,
        tabValue: 2
    }
];

const actionFormMetaData: FieldType[][] = [
    [
        {
            type: PROPERTY_FIELD_TYPE_MAP.TEXT,
            labelName: PARTNER_NAME,
            validations: {
                required: true,
                maxLength: 100
            }
        },
        {
            type: PROPERTY_FIELD_TYPE_MAP.TEXT,
            labelName: PARTNER_SALESFORCE_ID,
            validations: {
                required: true,
                maxLength: 18
            }
        },
        {
            type: PROPERTY_FIELD_TYPE_MAP.DROPDOWN,
            labelName: PARTNER_INSTANCE_REGION,
            validations: {
                required: true
            }
        },
        {
            type: PROPERTY_FIELD_TYPE_MAP.TEXTAREA,
            labelName: PARTNER_INSTANCE_NOTES,
            validations: {
                maxLength: 2000
            }
        }
    ],
    [
        {
            type: PROPERTY_FIELD_TYPE_MAP.TEXT,
            labelName: PARTNER_FIRST_NAME,
            validations: {
                required: true,
                maxLength: 100
            }
        },
        {
            type: PROPERTY_FIELD_TYPE_MAP.TEXT,
            labelName: PARTNER_LAST_NAME,
            validations: {
                required: true,
                maxLength: 100
            }
        },
        {
            type: PROPERTY_FIELD_TYPE_MAP.TEXT,
            labelName: PARTNER_EMAIL,
            validations: {
                required: true,
                regex: EMAIL_REGEX,
                maxLength: 254
            }
        },
        {
            type: PROPERTY_FIELD_TYPE_MAP.TEXT,
            labelName: PARTNER_PHONE_NUMBER,
            validations: {
                maxLength: 20
            }
        }
    ]
];

const AddPartner = ({
    currentMenuItem,
    setIsLoading,
    setModalOpen,
    modelOpen,
    modalMode,
    setModalMode,
    getAllPartners
}: AddPartnerProps): JSX.Element => {
    const [modalFields, setModalFields] = useState<ModalFieldType>(initialData);

    const [regions, setRegions] = useState<RegionData[]>([]);
    const [modalPage, setModalPage] = useState<number>(0);
    const [, startTransition] = useTransition();

    const editModalAction = () => {
        const clonedModalFields = cloneDeep(modalFields);
        Object.keys(clonedModalFields!).forEach(element => {
            const apiKey = PARTNER_API_MAPPING[element];
            let valueOfElement: string | number =
                currentMenuItem![apiKey as keyof typeof currentMenuItem];
            if (element === PARTNER_INSTANCE_REGION) {
                valueOfElement = 0;
            }
            clonedModalFields[element].value = valueOfElement;
            if (disableForEdit.includes(element)) {
                clonedModalFields[element].disable = true;
            }
        });
        setModalFields({ ...clonedModalFields });
    };

    useEffect(() => {
        if (modalMode === EDIT) {
            editModalAction();
            setModalOpen(true);
        }
    }, [modalMode]);

    useEffect(() => {
        if (modelOpen && modalMode === ADD) {
            setIsLoading(true);
            getRegions()
                .then((res: AxiosResponse<RegionResponseData>) => {
                    setRegions(res.data.content);
                    if (res.data.content.length > 0) {
                        setModalFields({
                            ...modalFields,
                            [PARTNER_INSTANCE_REGION]: {
                                ...modalFields[PARTNER_INSTANCE_REGION],
                                value: 0
                            }
                        });
                    }
                })
                .catch((err: AxiosError<ErrorResponse>) => {
                    displayErrorMessage(err);
                })
                .finally(() => {
                    setIsLoading(false);
                });
        }
    }, [modelOpen]);

    const checkFieldsAndNavigate = (
        event: React.SyntheticEvent<Element, Event>,
        newValue: number
    ) => {
        if (newValue <= modalPage) {
            setModalPage(newValue);
        } else if (newValue > modalPage) {
            if (
                range(modalPage, newValue, [1]).every(
                    pageNumber =>
                        !validateFormFields(
                            pageNumber,
                            setModalFields,
                            modalFields,
                            actionFormMetaData
                        )
                )
            ) {
                setModalPage(newValue);
            }
        }
    };
    const resetData = () => {
        setModalOpen(false);
        setModalMode(ADD);
        setModalFields(initialData);
        setModalPage(0);
    };
    const nextButtonClick = () => {
        if (modalPage < 2) {
            if (
                !validateFormFields(
                    modalPage,
                    setModalFields,
                    modalFields,
                    actionFormMetaData
                )
            ) {
                setModalPage(modalPage + 1);
            }
        } else if (modalPage === 2) {
            const keysObject = Object.keys(modalFields);
            const dataObject = keysObject.reduce(
                (totalObject: any, currentValue) => {
                    if (currentValue === PARTNER_INSTANCE_REGION) {
                        const regionIndex: number = modalFields[currentValue]
                            .value as number;

                        totalObject[PARTNER_API_MAPPING[currentValue]] = {
                            id:
                                modalMode === EDIT
                                    ? currentMenuItem!.regionId
                                    : regions[regionIndex].id
                        };
                    } else {
                        totalObject[PARTNER_API_MAPPING[currentValue]] =
                            modalFields[currentValue].value;
                    }
                    return totalObject;
                },
                {}
            );
            setIsLoading(true);
            if (modalMode === EDIT) {
                editPartner(currentMenuItem!.id, dataObject)
                    .then((res: AxiosResponse<PartnersType>) => {
                        getAllPartners();
                        const successMsg = PARTNER_EDIT_SUCCESS.replace(
                            '%s',
                            res.data.name
                        );
                        showToast(successMsg, TOAST_TYPE.SUCCESS, TOAST_ID);
                        resetData();
                    })
                    .catch((err: AxiosError<ErrorResponse>) => {
                        displayErrorMessage(err);
                    })
                    .finally(() => {
                        setIsLoading(false);
                    });
            } else {
                postFormDATA(dataObject)
                    .then((res: AxiosResponse<PartnersType>) => {
                        getAllPartners();
                        const successMsg = PARTNER_ADD_SUCCESS.replace(
                            '%s',
                            res.data.name
                        );
                        showToast(successMsg, TOAST_TYPE.SUCCESS, TOAST_ID);
                        resetData();
                    })
                    .catch((err: AxiosError<ErrorResponse>) => {
                        displayErrorMessage(err);
                    })
                    .finally(() => {
                        setIsLoading(false);
                    });
            }
        }
    };

    return (
        <Modal
            className="wide"
            dialogActionsButton={
                modalPage !== 0 ? (
                    <Button
                        color="primary"
                        onClick={() => setModalPage(modalPage - 1)}
                        size="large"
                        startIcon={<Back />}
                        style={{
                            maxWidth: '100px',
                            minWidth: '100px',
                            padding: '8px',
                            fontWeight: 'bolder'
                        }}
                        variant="text"
                    >
                        {BACK}
                    </Button>
                ) : null
            }
            displayBtn="all"
            isModalOpen={modelOpen}
            onCancel={() => {
                resetData();
            }}
            onSubmit={nextButtonClick}
            submitBtnLabel={modalPage === 2 ? SUBMIT : NEXT}
            title={modalMode === ADD ? ADD_PARTNER : EDIT_PARTNER}
        >
            <TabGroup
                onChange={checkFieldsAndNavigate}
                sx={{
                    '& .scroll-wrapper': {
                        width: '100%'
                    },
                    '& .MuiTab-root': {
                        width: '33.3%'
                    }
                }}
                tabsItems={tabsItems}
                value={modalPage}
            />
            {modalPage === 0 && (
                <StyledContainer minHeight={392}>
                    <StyledSection noBorder>
                        {actionFormMetaData[modalPage].map(
                            ({ labelName, type }, index) => (
                                <ModalField
                                    {...modalFields[labelName]}
                                    key={index}
                                    index={index}
                                    items={
                                        labelName === PARTNER_INSTANCE_REGION
                                            ? modalMode === EDIT
                                                ? [
                                                      currentMenuItem?.[
                                                          PARTNER_API_MAPPING[
                                                              PARTNER_INSTANCE_REGION
                                                          ] as keyof typeof currentMenuItem
                                                      ] as string
                                                  ]
                                                : regions.map(
                                                      element => element.name
                                                  )
                                            : []
                                    }
                                    labelName={labelName}
                                    onChange={fieldValue =>
                                        onFieldChangeHandler(
                                            fieldValue,
                                            labelName,
                                            type,
                                            actionFormMetaData,
                                            setModalFields,
                                            modalPage,
                                            modalFields,
                                            startTransition
                                        )
                                    }
                                    type={type}
                                />
                            )
                        )}
                    </StyledSection>
                </StyledContainer>
            )}
            {modalPage === 1 && (
                <StyledContainer minHeight={392}>
                    <StyledSection noBorder>
                        <StyledCombinedField>
                            {actionFormMetaData[modalPage]
                                .slice(0, 2)
                                .map(({ labelName, type }, index) => (
                                    <ModalField
                                        {...modalFields[labelName]}
                                        key={index}
                                        index={index}
                                        labelName={labelName}
                                        onChange={fieldValue =>
                                            onFieldChangeHandler(
                                                fieldValue,
                                                labelName,
                                                type,
                                                actionFormMetaData,
                                                setModalFields,
                                                modalPage,
                                                modalFields,
                                                startTransition
                                            )
                                        }
                                    />
                                ))}
                        </StyledCombinedField>
                        {actionFormMetaData[modalPage]
                            .slice(2)
                            .map(({ labelName, type }, index) => (
                                <ModalField
                                    {...modalFields[labelName]}
                                    key={index}
                                    index={index}
                                    labelName={labelName}
                                    onChange={fieldValue =>
                                        onFieldChangeHandler(
                                            fieldValue,
                                            labelName,
                                            type,
                                            actionFormMetaData,
                                            setModalFields,
                                            modalPage,
                                            modalFields,
                                            startTransition
                                        )
                                    }
                                    type={type}
                                />
                            ))}
                    </StyledSection>
                </StyledContainer>
            )}
            {modalPage === 2 && (
                <StyledContainer minHeight={392}>
                    <StyledReview>
                        <ReviewPageTitle
                            navigateFunction={() => setModalPage(0)}
                            pageTitle={pageNames[0]}
                        />
                        <StyledBox>
                            {actionFormMetaData[0].map(
                                ({ labelName, type }, index) => {
                                    const preValue =
                                        modalFields[labelName].value;
                                    const fieldValue =
                                        type ===
                                        PROPERTY_FIELD_TYPE_MAP.DROPDOWN
                                            ? modalMode === EDIT
                                                ? (currentMenuItem?.[
                                                      PARTNER_API_MAPPING[
                                                          PARTNER_INSTANCE_REGION
                                                      ] as keyof typeof currentMenuItem
                                                  ] as string)
                                                : regions[preValue as number]
                                                      .name
                                            : preValue;
                                    return (
                                        <ReviewPageRow
                                            key={index}
                                            fieldValue={fieldValue as string}
                                            index={index}
                                            labelName={labelName}
                                        />
                                    );
                                }
                            )}
                        </StyledBox>
                    </StyledReview>
                    <StyledReview>
                        <ReviewPageTitle
                            navigateFunction={() => setModalPage(1)}
                            pageTitle={pageNames[1]}
                        />
                        <StyledBox>
                            <Box
                                sx={{
                                    display: 'flex',
                                    '> div': {
                                        width: '49%'
                                    }
                                }}
                            >
                                {actionFormMetaData[1]
                                    .slice(0, 2)
                                    .map(({ labelName }, index) => (
                                        <ReviewPageRow
                                            key={index}
                                            fieldValue={
                                                modalFields[labelName]
                                                    .value as string
                                            }
                                            index={index}
                                            labelName={labelName}
                                        />
                                    ))}
                            </Box>
                            {actionFormMetaData[1]
                                .slice(2)
                                .map(({ labelName }, index) => (
                                    <ReviewPageRow
                                        key={index}
                                        fieldValue={
                                            modalFields[labelName]
                                                .value as string
                                        }
                                        index={index}
                                        labelName={labelName}
                                    />
                                ))}
                        </StyledBox>
                    </StyledReview>
                </StyledContainer>
            )}
        </Modal>
    );
};

AddPartner.displayName = ADD_PARTNER;
export default AddPartner;
