import React, { useRef, useState, useMemo, useEffect } from 'react';

import {
    Table,
    CustomHeader,
    Pagination,
    StyledMenuPaper,
    MultiSelect,
    TableSkeleton,
    TableNoData,
    Button,
    IconButton,
    MenuIcon,
    IconPencil,
    IconDelete,
    WhitePlus
} from '@armis/armis-ui-library';
import { Menu, PaperProps, MenuItem } from '@mui/material';
import { SelectionChangedEvent } from 'ag-grid-community';
import { AxiosError, AxiosResponse } from 'axios';
import { cloneDeep } from 'lodash';
import { useSelector } from 'react-redux';
import { TOAST_ID } from 'src/constants/APIConstants';
import { Privileges, Resources } from 'src/constants/CommonConstants';
import {
    ACTIONS,
    ADD_USER,
    ADD_USER_BUTTON,
    DELETE_MULTIPLE_USER,
    DELETE_SINGLE_USER,
    DELETE_USER,
    DELETE_USERS,
    EDIT_USER,
    OK,
    REQUIRED,
    SAVE_CHANGES,
    SEARCH_USER_PLACEHOLDER,
    USERS_DELETED_SUCCESS,
    USERS_MANAGEMENT_HEADER,
    USER_ADDED_SUCCESS,
    USER_DELETED_SUCCESS,
    USER_MANAGEMENT_HEADER,
    USER_UPDATED_SUCCESS
} from 'src/constants/LabelText';
import {
    userManagementAPIMapping,
    createRelatedObject,
    DEFAULT_PAGE,
    DEFAULT_PAGESIZE,
    NO_DATA_TO_SHOW
} from 'src/constants/TableConstants';
import { userManagementDef } from 'src/helpers/ColumnsConfig';
import {
    convertQueryObjectToParams,
    displayErrorMessage,
    getDirectionsAndProperties,
    isActionHasPermissions,
    showToast,
    TOAST_TYPE,
    validateValues
} from 'src/helpers/utility';
import IsLoadingHOC from 'src/hoc/IsLoadingHoc';
import { useTable } from 'src/hooks/useTable';
import { ProtectedAction } from 'src/pages/common/ProtectedAction';
import { ConfirmAction } from 'src/pages/components/ConfirmAction';
import { Header } from 'src/pages/components/Header';
import { Modal } from 'src/pages/components/Modal';
import SearchBar from 'src/pages/components/SearchBar/SearchBar';
import { TableHeader } from 'src/pages/components/TableHeader';
import AddUserModalContainer from 'src/pages/components/UserModalContainer/AddUserModalContainer'; // UserFieldMetaData
import { WarningModalContainer } from 'src/pages/components/WarningModalContainer/WarningModalContainer';
import { StyledOption } from 'src/pages/containers/UserManagement/UserManagement.style';
import {
    addUserData,
    deleteBulkUsers,
    deleteUser,
    getUserData,
    updateUserDetails
} from 'src/services/api.service';
import { selectUser } from 'src/store/slices/userSlice';
import { AddUserPayload } from 'src/types/APIPayloadTypes';
import { ErrorResponse } from 'src/types/APIResponseTypes';
import { Map, FilterItems, ChildRefProp } from 'src/types/CommonTypes';

import { ACTION_TYPE, UserFieldMetaData } from './UserConstants';
import {
    UserManagementData,
    UserManagementProps
} from './UserManagement.types';

const userManagementSortOrder: Map<number> = {};
const userManagementSortStatus: Map<string> = {};
const columnsFilterItems: FilterItems[] = [];

createRelatedObject(
    userManagementAPIMapping,
    userManagementSortOrder,
    userManagementSortStatus,
    columnsFilterItems
);

export const initialData: {
    [key: string]: string;
} = {
    firstname: '',
    lastname: '',
    username: '',
    phonenumber: ''
};

const UserManagementComponent = ({ setIsLoading }: UserManagementProps) => {
    const {
        tableLoading,
        setTableLoading,
        columnSortOrder,
        columnSortStatus,
        gridRef,
        filterItems,
        anchorEl,
        onSortChangedCall,
        handleMenuClick,
        handleMenuClose,
        onSelectionChanged,
        queryProperties,
        setQueryProperties
    } = useTable({
        sortOrderObj: userManagementSortOrder,
        sortStatusObj: userManagementSortStatus,
        columnsFilterItems
    });

    const [roleSelected, setRoleSelected] = useState([]);
    const [password, setPassword] = useState('');
    const [rowData, setRowData] = useState<UserManagementData[]>([]);
    const [usersData, setUsersData] = useState(initialData);
    const loggedInUser = useSelector(selectUser);
    const [userErrors, setUserErrors] = useState<any>({});
    const [totalRows, setTotalRows] = useState(0);
    const [selectedUsers, setSelectedUsers] = useState<string[]>([]);
    const [selectedUsernames, setSelectedUsernames] = useState<string[]>([]);
    const [isConfirmActionModalOpen, setIsConfirmActionModalOpen] =
        useState(false);
    const [anchorElMenu, setAnchorElMenu] = useState<null | HTMLElement>(null);
    const [modelOpenDelete, setModelOpenDelete] = useState(false);
    const [modelOpenAdd, setModelOpenAdd] = useState(false);
    const [cup, setCup] = useState('');
    const [actionType, setActionType] = useState('');
    const paginationRef = useRef<ChildRefProp>();
    const firstRender = useRef(true);
    const defaultColDefs = useMemo(
        () => ({
            headerComponent: CustomHeader,
            headerComponentParams: {
                onSortChanged: onSortChangedCall,
                columnSortOrder,
                columnSortStatus
            },
            resizable: true,
            filter: false
        }),
        [columnSortOrder, onSortChangedCall, columnSortStatus]
    );
    const columnInfo = useMemo(() => {
        const hasAccess = isActionHasPermissions(
            loggedInUser,
            Resources.users,
            [Privileges.edit, Privileges.delete]
        );
        if (hasAccess) return userManagementDef;
        return cloneDeep(userManagementDef).slice(1);
    }, []);
    const getUsers = () => {
        setTableLoading(true);
        setRowData([]);
        getUserData(convertQueryObjectToParams(queryProperties))
            .then((res: AxiosResponse) => {
                setRowData(res.data.content);
                setTotalRows(res.data.totalElements);
            })
            .catch(err => {
                displayErrorMessage(err);
                setRowData([]);
                setTotalRows(0);
            })
            .finally(() => {
                setTableLoading(false);
                setSelectedUsers([]);
                if (
                    queryProperties.page === DEFAULT_PAGE &&
                    queryProperties.size === DEFAULT_PAGESIZE
                )
                    paginationRef.current?.resetPagination();
            });
    };
    const searchUserContainer = useMemo(
        () => (
            <div>
                <SearchBar
                    onChange={newValue =>
                        setQueryProperties(prevValue => ({
                            ...prevValue,
                            searchBy: newValue,
                            page: DEFAULT_PAGE,
                            size: DEFAULT_PAGESIZE
                        }))
                    }
                    placeholder={SEARCH_USER_PLACEHOLDER}
                    searchValue={queryProperties.searchBy as string}
                />
            </div>
        ),
        [queryProperties.searchBy]
    );

    useEffect(() => {
        getUsers();
    }, [queryProperties, setTableLoading]);

    useEffect(() => {
        if (firstRender.current) {
            firstRender.current = false;
            return;
        }
        const { directions, properties } = getDirectionsAndProperties(
            columnSortOrder,
            columnSortStatus
        );
        setQueryProperties(prevValue => ({
            ...prevValue,
            directions,
            properties,
            page: DEFAULT_PAGE,
            size: DEFAULT_PAGESIZE
        }));
        paginationRef.current?.resetPagination();
    }, [columnSortStatus, columnSortOrder, setQueryProperties]);

    useEffect(() => {
        if (!gridRef.current?.api) return;
        if (rowData.length === 0 && !tableLoading && !firstRender.current) {
            setTimeout(() => {
                gridRef.current?.api.showNoRowsOverlay();
            }, 100);
        }
    }, [rowData, tableLoading, gridRef]);

    const onClickCancel = () => {
        setIsConfirmActionModalOpen(false);
        setModelOpenAdd(false);
        setModelOpenDelete(false);
        setPassword('');
    };
    const openMenu = Boolean(anchorElMenu);
    const handleClickMenu = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorElMenu(event.currentTarget);
    };
    const handleCloseMenu = () => {
        setAnchorElMenu(null);
    };

    const deleteUsers = () => {
        setIsLoading(true);
        if (selectedUsers.length === 1) {
            deleteUser(selectedUsers.toString(), { cup })
                .then(() => {
                    setModelOpenDelete(false);
                    showToast(
                        USER_DELETED_SUCCESS.replace(
                            '%s',
                            selectedUsernames.toString()
                        ),
                        TOAST_TYPE.SUCCESS,
                        TOAST_ID
                    );
                })
                .catch((err: AxiosError<ErrorResponse>) => {
                    displayErrorMessage(err);
                })
                .finally(() => {
                    setAnchorElMenu(null);
                    getUsers();
                    setIsLoading(false);
                });
        } else {
            deleteBulkUsers({
                userIds: selectedUsers,
                verifyPassword: { cup }
            })
                .then(() => {
                    setModelOpenDelete(false);
                    showToast(
                        USERS_DELETED_SUCCESS,
                        TOAST_TYPE.SUCCESS,
                        TOAST_ID
                    );
                })
                .catch((err: AxiosError<ErrorResponse>) => {
                    displayErrorMessage(err);
                })
                .finally(() => {
                    setAnchorElMenu(null);
                    getUsers();
                    setIsLoading(false);
                });
        }
        setCup('');
    };
    const rowSelectionChangeHandler = (
        event: SelectionChangedEvent<UserManagementData>
    ) => {
        setSelectedUsers(event.api.getSelectedRows().map(item => item.id));
        setSelectedUsernames(
            event.api
                .getSelectedRows()
                .map(item => `${item.firstname} ${item.lastname}`)
        );
    };

    const userData = (data: any) => {
        setUsersData(data);
    };

    const rolesSelected = (data: any) => {
        setRoleSelected(data);
    };

    useEffect(() => {
        if (password !== '') {
            setCup(password);
        }
    }, [password]);

    const addUserAPICall = (addUser: AddUserPayload) => {
        setIsLoading(true);
        if (actionType === ACTION_TYPE.ADD) {
            addUserData(addUser)
                .then(() => {
                    showToast(
                        USER_ADDED_SUCCESS.replace(
                            '%s',
                            `${addUser.userDetail.firstname} ${addUser.userDetail.lastname}`
                        ),
                        TOAST_TYPE.SUCCESS,
                        TOAST_ID
                    );
                    getUsers();
                    setModelOpenAdd(false);
                    setUsersData(initialData);
                    setRoleSelected([]);
                })
                .catch((err: AxiosError<ErrorResponse>) => {
                    displayErrorMessage(err);
                    setModelOpenAdd(true);
                })
                .finally(() => {
                    setUserErrors({});
                    setIsLoading(false);
                });
        } else if (actionType === ACTION_TYPE.EDIT) {
            updateUserDetails(selectedUsers.toString(), addUser)
                .then(() => {
                    showToast(
                        USER_UPDATED_SUCCESS.replace(
                            '%s',
                            selectedUsernames.toString()
                        ),
                        TOAST_TYPE.SUCCESS,
                        TOAST_ID
                    );
                    getUsers();
                    setModelOpenAdd(false);
                    setUsersData(initialData);
                    setRoleSelected([]);
                })
                .catch((err: AxiosError<ErrorResponse>) => {
                    displayErrorMessage(err);
                    setModelOpenAdd(true);
                })
                .finally(() => {
                    setUserErrors({});
                    setIsLoading(false);
                    setAnchorElMenu(null);
                });
        }
        setCup('');
    };

    const validateAndSubmit = () => {
        let fieldValidationErrorArray = {};
        const usersDataKeys = Object.keys(usersData);
        usersDataKeys?.forEach(propertyObj => {
            if (UserFieldMetaData[propertyObj]) {
                const validationErrorObject = validateValues(
                    usersData[propertyObj],
                    UserFieldMetaData[propertyObj].validations,
                    UserFieldMetaData[propertyObj].type
                );
                if (validationErrorObject.error)
                    fieldValidationErrorArray = {
                        ...fieldValidationErrorArray,
                        [propertyObj]: {
                            ...validationErrorObject
                        }
                    };
            }
        });
        if (roleSelected.length === 0) {
            fieldValidationErrorArray = {
                ...fieldValidationErrorArray,
                roles: { error: true, helperText: REQUIRED }
            };
        }
        if (Object.keys(fieldValidationErrorArray).length > 0) {
            setUserErrors(fieldValidationErrorArray);
        } else {
            let rolesObj = [{}];
            roleSelected.forEach(item => {
                rolesObj.push({ id: item });
            });
            rolesObj = rolesObj.slice(1, rolesObj.length);
            const userPersonalDetails = {
                ...usersData,
                roles: rolesObj
            };
            const userDetails = { userDetail: userPersonalDetails, cup };
            addUserAPICall(userDetails);
        }
    };

    return (
        <>
            <div style={{ marginTop: '15px', marginBottom: '5px' }}>
                <Header title={USERS_MANAGEMENT_HEADER}>
                    <ProtectedAction
                        hasPermissions={[Privileges.create]}
                        resource={Resources.users}
                    >
                        <Button
                            className="header-add-button"
                            color="primary"
                            disabled={tableLoading}
                            onClick={() => {
                                setIsConfirmActionModalOpen(true);
                                setModelOpenAdd(true);
                                setActionType(ACTION_TYPE.ADD);
                            }}
                            startIcon={<WhitePlus />}
                            variant="contained"
                        >
                            {ADD_USER}
                        </Button>
                    </ProtectedAction>
                </Header>
            </div>
            <div className="control table">
                <TableHeader
                    childrenLeft={
                        <>
                            {searchUserContainer}
                            <>
                                <ProtectedAction
                                    hasAnyPermission={[
                                        Privileges.edit,
                                        Privileges.delete
                                    ]}
                                    resource={Resources.users}
                                >
                                    <IconButton
                                        aria-label={ACTIONS}
                                        disabled={!(selectedUsers.length > 0)}
                                        onClick={handleClickMenu}
                                    >
                                        <MenuIcon />
                                    </IconButton>
                                </ProtectedAction>
                                <Menu
                                    anchorEl={anchorElMenu}
                                    id="button-dropdown"
                                    onClose={handleCloseMenu}
                                    open={openMenu}
                                    PaperProps={
                                        {
                                            component: StyledMenuPaper,
                                            sx: {
                                                zIndex: 10002,
                                                maxHeight: '268px'
                                            }
                                        } as
                                            | Partial<PaperProps<'div', {}>>
                                            | undefined
                                    }
                                >
                                    <ProtectedAction
                                        hasPermissions={[Privileges.edit]}
                                        resource={Resources.users}
                                    >
                                        <MenuItem
                                            disabled={selectedUsers.length > 1}
                                            onClick={() => {
                                                setUserErrors({});
                                                setIsConfirmActionModalOpen(
                                                    true
                                                );
                                                setModelOpenAdd(true);
                                                setActionType(ACTION_TYPE.EDIT);
                                            }}
                                            value="edit"
                                        >
                                            <IconPencil
                                                height={16}
                                                style={{ marginTop: '1px' }}
                                                width={16}
                                            />
                                            <StyledOption>
                                                {EDIT_USER}
                                            </StyledOption>
                                        </MenuItem>
                                    </ProtectedAction>
                                    <ProtectedAction
                                        hasPermissions={[Privileges.delete]}
                                        resource={Resources.users}
                                    >
                                        <MenuItem
                                            onClick={() => {
                                                setIsConfirmActionModalOpen(
                                                    true
                                                );
                                                setModelOpenDelete(true);
                                            }}
                                            value="delete"
                                        >
                                            <IconDelete />
                                            <StyledOption>
                                                {selectedUsers.length > 1
                                                    ? DELETE_USERS
                                                    : DELETE_USER}
                                            </StyledOption>
                                        </MenuItem>
                                    </ProtectedAction>
                                </Menu>
                            </>
                        </>
                    }
                    loading={tableLoading}
                    onColumnMenuClick={handleMenuClick}
                    selectedCount={selectedUsers.length}
                    title={`${totalRows} ${
                        totalRows === 1
                            ? USER_MANAGEMENT_HEADER
                            : USERS_MANAGEMENT_HEADER
                    }`}
                />

                {!loggedInUser.issso && isConfirmActionModalOpen ? (
                    <ConfirmAction
                        key="confirm-action-modal"
                        onClickCancel={onClickCancel}
                        onSubmitSuccess={() => {
                            setIsLoading(false);
                            setIsConfirmActionModalOpen(false);
                            setPassword('');
                        }}
                        password={password}
                        setIsLoading={setIsLoading}
                        setPassword={setPassword}
                    />
                ) : modelOpenDelete ? (
                    <Modal
                        displayBtn="all"
                        isModalOpen={modelOpenDelete}
                        onCancel={() => {
                            setModelOpenDelete(false);
                            setIsConfirmActionModalOpen(false);
                        }}
                        onSubmit={() => {
                            deleteUsers();
                            setIsConfirmActionModalOpen(false);
                        }}
                        submitBtnLabel={OK}
                        title={
                            selectedUsers.length > 1
                                ? DELETE_USERS
                                : DELETE_USER
                        }
                    >
                        <WarningModalContainer
                            text={
                                selectedUsers.length > 1
                                    ? DELETE_MULTIPLE_USER.replace(
                                          '%s',
                                          selectedUsers.length.toString()
                                      )
                                    : DELETE_SINGLE_USER.replace(
                                          '%s',
                                          selectedUsernames.toString()
                                      )
                            }
                        />
                    </Modal>
                ) : modelOpenAdd ? (
                    <Modal
                        className="large"
                        displayBtn="all"
                        isModalOpen={modelOpenAdd}
                        onCancel={() => {
                            setUserErrors({});
                            setModelOpenAdd(false);
                            setUsersData(initialData);
                            setRoleSelected([]);
                        }}
                        onSubmit={() => {
                            validateAndSubmit();
                        }}
                        submitBtnLabel={
                            actionType === ACTION_TYPE.ADD
                                ? ADD_USER_BUTTON
                                : SAVE_CHANGES
                        }
                        title={
                            actionType === ACTION_TYPE.ADD
                                ? ADD_USER
                                : EDIT_USER
                        }
                    >
                        <AddUserModalContainer
                            actionType={actionType}
                            customError={userErrors}
                            items={selectedUsers}
                            onAddRoles={rolesSelected}
                            onUserAdd={userData}
                            selectedRole={roleSelected}
                            setIsLoading={setIsLoading}
                            usersData={usersData}
                        />
                    </Modal>
                ) : (
                    ''
                )}
                <Menu
                    anchorEl={anchorEl}
                    onClose={handleMenuClose}
                    open={Boolean(anchorEl)}
                    PaperProps={
                        {
                            component: StyledMenuPaper
                        } as Partial<PaperProps<'div', {}>> | undefined
                    }
                >
                    <MultiSelect
                        items={filterItems}
                        onSelectionChanged={onSelectionChanged}
                        showSelectAllOption
                    />
                </Menu>

                <Table
                    ref={gridRef}
                    columnDefs={columnInfo}
                    defaultColDef={defaultColDefs}
                    loadingOverlayComponent={TableSkeleton}
                    noRowsOverlayComponent={TableNoData}
                    noRowsOverlayComponentParams={{
                        content: NO_DATA_TO_SHOW
                    }}
                    onSelectionChanged={rowSelectionChangeHandler}
                    rowData={rowData}
                    suppressNoRowsOverlay={firstRender.current}
                />
                {!!totalRows && totalRows > 0 && (
                    <Pagination
                        ref={paginationRef}
                        onPaginationChanged={(page, size) => {
                            setQueryProperties(prevValue => ({
                                ...prevValue,
                                page,
                                size
                            }));
                        }}
                        totalRowsCount={totalRows}
                    />
                )}
            </div>
        </>
    );
};
export const UserManagement = IsLoadingHOC(UserManagementComponent);
