import React from 'react';

import { SELECTED } from 'src/constants/LabelText';
import { Spacer } from 'src/pages/components/TopBar/TopBar.style';
import { StyledContentHeader } from 'src/pages/containers/RoleManagement/RoleManagement.style';
import { ContentHeaderProps } from 'src/pages/containers/RoleManagement/RoleManagement.types';

export const ContentHeader = ({
    roleCount,
    selectedRoleCount,
    title,
    children
}: ContentHeaderProps) => (
    <StyledContentHeader>
        <span className="title">
            <span className="large">{`${roleCount} ${title}`}</span>
            {!!selectedRoleCount && (
                <>
                    <span className="selected-count">|</span>
                    <span className="selected-count">
                        {`${selectedRoleCount} ${SELECTED}`}
                    </span>
                </>
            )}
        </span>
        <Spacer />
        {children}
    </StyledContentHeader>
);
