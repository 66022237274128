import { constants } from '@armis/armis-ui-library';
import { Avatar, styled } from '@mui/material';

export const StyledTopBar = styled('div')`
    background-color: ${props =>
        props.theme.palette.mode === 'dark' ? '#15111D' : '#FFF'};
    position: relative;

    &:not(.no-shadow)::after {
        content: '';
        // box-shadow: 0 5px 5px -4px rgb(0 0 0 / 28%);
        position: absolute;
        height: 10px;
        right: 0;
        left: 0;
        top: calc(100% - 10px);
    }
`;

export const ProfileContainer = styled('div')`
    background-color: ${props =>
        props.theme.palette.mode === 'dark' ? '#251E33' : '#FFFFFF'};
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 12px 24px;
    border-bottom: 1px solid
        ${props =>
            props.theme.palette.mode === 'dark' ? '#352D45' : '#e8e8e8'};
    z-index: 9998;
`;

export const Spacer = styled('div')`
    flex: 1;
`;

export const StyledAvatar = styled(Avatar)`
    height: 32px;
    width: 32px;
    font-size: 1.6rem;
    background-color: #372564;
    color: #fff;
    text-align: center;
`;

export const StyledHeader = styled('div')`
    padding: 10px;
    border-bottom: 1px solid
        ${props =>
            props.theme.palette.mode === 'dark'
                ? constants.COLOR_GENERAL_28
                : constants.COLOR_GENERAL_7};
    div {
        line-height: 1;
        font-size: 14px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
`;

export const UserNameContainer = styled('div')`
    margin-bottom: 8px;
    font-family: ${constants.PROXIMANOVA_W08_BOLD};
    color: ${props =>
        props.theme.palette.mode === 'dark'
            ? constants.COLOR_GENERAL_25
            : constants.COLOR_GENERAL_3};
`;

export const UserEmailContainer = styled('div')`
    color: ${props =>
        props.theme.palette.mode === 'dark'
            ? constants.COLOR_GENERAL_25
            : constants.COLOR_GENERAL_4};
`;

export const StyledFooter = styled('div')`
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    line-height: 28px;
    padding: 0 15px;
    text-align: center;
    background-color: ${props =>
        props.theme.palette.mode === 'dark'
            ? constants.COLOR_GENERAL_27
            : constants.COLOR_GENERAL_21};
    color: ${props =>
        props.theme.palette.mode === 'dark'
            ? constants.COLOR_GENERAL_25
            : constants.COLOR_GENERAL_22};
    font-size: 14px;
    font-family: ${constants.PROXIMANOVA_W08_BOLD};
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
`;

export const DarkModeMenuItem = styled('div')`
    width: 257px;
    display: flex;
    justify-content: space-between;
    align-items: center;
`;
