import React from 'react';

import {
    toastHook,
    updateToastHook,
    originalToast
} from '@armis/armis-ui-library';
import { AxiosError } from 'axios';
import { TypeOptions } from 'react-toastify';
import { TOAST_ID } from 'src/constants/APIConstants';
import { PROPERTY_FIELD_TYPE_MAP } from 'src/constants/CommonConstants';
import {
    INVALID_URL,
    INVALID_VALUE_INSERT,
    MAX_LENGTH_INVALID,
    MAX_VALUE_INVALID,
    MIN_VALUE_INVALID,
    REQUIRED,
    TIMEOUT_ERROR
} from 'src/constants/LabelText';
import { Validators } from 'src/helpers/Validators';
import { ErrorResponse, User } from 'src/types/APIResponseTypes';
import {
    APICallType,
    CustomPropertyFieldError,
    FieldType,
    Map,
    ModalFieldType,
    PropertyValidationType
} from 'src/types/CommonTypes';

export const TOAST_TYPE = {
    SUCCESS: 'success' as 'success',
    ERROR: 'error' as 'error',
    INFO: 'info' as 'info',
    WARNING: 'warning' as 'warning',
    DEFAULT: 'default' as 'default'
};

let toastIdValue: string | null = null;
export const showToast = (
    content: string,
    type: TypeOptions,
    toastId: string
) => {
    if (toastIdValue === null || !originalToast.isActive(toastIdValue)) {
        toastHook({
            content,
            options: {
                type,
                toastId
            }
        });
    } else {
        updateToastHook({
            toastId,
            options: {
                render: content,
                type
            }
        });
    }
    toastIdValue = toastId;
};

export const displayErrorMessage = (err: AxiosError<ErrorResponse>) => {
    if (err.response) {
        showToast(err.response.data?.message!, TOAST_TYPE.ERROR, TOAST_ID);
    } else if (err.code === 'ECONNABORTED') {
        showToast(TIMEOUT_ERROR, TOAST_TYPE.ERROR, TOAST_ID);
    } else if (err.message) {
        showToast(err.message, TOAST_TYPE.ERROR, TOAST_ID);
    }
};

export const getDirectionsAndProperties = (
    columnSortOrder: Map<number>,
    columnSortStatus: Map<string>
) => {
    let directions = '';
    let properties = '';
    const keysSorted = Object.keys(columnSortOrder).sort(
        (a, b) => columnSortOrder[a] - columnSortOrder[b]
    );
    keysSorted.forEach(element => {
        if (columnSortOrder[element] >= 0) {
            directions += `${columnSortStatus[element]},`;
            properties += `${element},`;
        }
    });
    directions = directions.slice(0, -1);
    properties = properties.slice(0, -1);
    return {
        directions,
        properties
    };
};

export const convertQueryObjectToParams = (queryObj: APICallType) => {
    let queryParams = '';
    Object.entries(queryObj).forEach(([key, value], index, array) => {
        queryParams += `${key}=${encodeURIComponent(value)}${
            index === array.length - 1 ? '' : '&'
        }`;
    });
    return queryParams;
};

export const validateValues = (
    value: string,
    validations: PropertyValidationType,
    fieldType: string
) => {
    if (validations.required && !Validators.validateNotEmpty(value))
        return {
            error: true,
            helperText: REQUIRED
        } as CustomPropertyFieldError;
    if (
        validations.minValue &&
        Validators.validateMinValue(+value, Number(validations.minValue)) &&
        fieldType !== PROPERTY_FIELD_TYPE_MAP.SCHEDULER
    )
        return {
            error: true,
            helperText: MIN_VALUE_INVALID.replace(
                '%s',
                validations.minValue.toString()
            )
        } as CustomPropertyFieldError;
    if (
        validations.maxValue &&
        Validators.validateMaxValue(+value, validations.maxValue) &&
        fieldType !== PROPERTY_FIELD_TYPE_MAP.SCHEDULER
    )
        return {
            error: true,
            helperText: MAX_VALUE_INVALID.replace(
                '%s',
                validations.maxValue.toString()
            )
        } as CustomPropertyFieldError;
    if (
        validations.regex &&
        !Validators.validateAgainstFormat(value, validations.regex)
    )
        return {
            error: true,
            helperText: INVALID_VALUE_INSERT
        } as CustomPropertyFieldError;
    if (
        value &&
        validations.maxLength &&
        !Validators.validateMaxLength(value, validations.maxLength)
    ) {
        return {
            error: true,
            helperText: MAX_LENGTH_INVALID.replace(
                '%s',
                validations.maxLength.toString()
            )
        };
    }
    if (value && validations.validUrl && !Validators.validateUrl(value)) {
        return {
            error: true,
            helperText: INVALID_URL
        };
    }
    return {
        error: false,
        helperText: ''
    } as CustomPropertyFieldError;
};

export const validateFormFields = (
    pageNumber: number,
    setModalFields: React.Dispatch<React.SetStateAction<ModalFieldType>>,
    modalFields: ModalFieldType,
    actionFormMetaData: FieldType[][]
) => {
    let thereIsError = false;
    let fieldsObject = {};
    actionFormMetaData[pageNumber]?.forEach(
        ({ labelName, type, validations }) => {
            if (
                type === PROPERTY_FIELD_TYPE_MAP.TEXT ||
                type === PROPERTY_FIELD_TYPE_MAP.TEXTAREA
            ) {
                const fieldValue = modalFields[labelName].value;
                const { error, helperText } = validateValues(
                    fieldValue as string,
                    validations!,
                    type
                );
                if (error) {
                    thereIsError = true;
                    fieldsObject = {
                        ...fieldsObject,
                        [labelName]: {
                            value: fieldValue,
                            error,
                            helperText
                        }
                    };
                }
            } else if (type === PROPERTY_FIELD_TYPE_MAP.DROPDOWN) {
                const fieldValue = modalFields[labelName].value;
                if (validations.required) {
                    if (fieldValue === '') {
                        thereIsError = true;
                        fieldsObject = {
                            ...fieldsObject,
                            [labelName]: {
                                value: fieldValue,
                                error: true,
                                helperText: REQUIRED
                            }
                        };
                    }
                }
            }
        }
    );
    if (thereIsError) {
        setModalFields({
            ...modalFields,
            ...fieldsObject
        });
    }
    return thereIsError;
};

export const onFieldChangeHandler = (
    fieldValue: string,
    labelName: string,
    type: string,
    actionFormMetaData: FieldType[][],
    setModalFields: React.Dispatch<React.SetStateAction<ModalFieldType>>,
    modelPage: number,
    modalFields: ModalFieldType,
    startTransition: React.TransitionStartFunction
) => {
    const fieldObject = (
        actionFormMetaData[
            modelPage as keyof typeof actionFormMetaData
        ] as any[]
    ).find((element: any) => element.labelName === labelName);
    if (
        type === PROPERTY_FIELD_TYPE_MAP.TEXT ||
        type === PROPERTY_FIELD_TYPE_MAP.TEXTAREA
    ) {
        setModalFields({
            ...modalFields,
            [labelName]: {
                ...modalFields[labelName],
                value: fieldValue
            }
        });

        const { error, helperText } = validateValues(
            fieldValue,
            fieldObject.validations,
            fieldObject.type
        );
        startTransition(() => {
            setModalFields({
                ...modalFields,
                [labelName]: {
                    ...modalFields[labelName],
                    value: fieldValue,
                    error,
                    helperText
                }
            });
        });
    } else if (type === PROPERTY_FIELD_TYPE_MAP.DROPDOWN) {
        let error = false;
        let helperText = '';
        if (fieldObject.validations.Required && fieldValue === '') {
            error = true;
            helperText = REQUIRED;
        }
        setModalFields({
            ...modalFields,
            [labelName]: {
                ...modalFields[labelName],
                error,
                helperText,
                value: Number(fieldValue)
            }
        });
    }
};
export const isFieldHavingError = (field: string, error: any) =>
    error?.[field]?.error ?? false;

export const urlFilter = (url: string) => url.replace(/\/+$/g, '');

export const isActionHasPermissions = (
    user: User,
    resource: string,
    hasPermissions: string[]
) => {
    const findResource = user.resources.find(r => r.name === resource);
    return hasPermissions.some(permission =>
        findResource?.privilegeNames.includes(permission)
    );
};
