import {
    PARTNER_EMAIL,
    PARTNER_FIRST_NAME,
    PARTNER_INSTANCE_REGION,
    PARTNER_INSTANCE_NOTES,
    PARTNER_LAST_NAME,
    PARTNER_NAME,
    PARTNER_PHONE_NUMBER,
    PARTNER_SALESFORCE_ID,
    PASSWORD,
    SECRET_KEY,
    TENANT_NAME,
    TENANT_URL,
    USERNAME
} from 'src/constants/LabelText';
import { Map } from 'src/types/CommonTypes';

export const PARTNER_API_MAPPING: Map<string> = {
    [PARTNER_NAME]: 'name',
    [PARTNER_SALESFORCE_ID]: 'salesForceId',
    [PARTNER_FIRST_NAME]: 'adminFirstName',
    [PARTNER_LAST_NAME]: 'adminLastName',
    [PARTNER_EMAIL]: 'adminEmail',
    [PARTNER_PHONE_NUMBER]: 'adminPhoneNumber',
    [PARTNER_INSTANCE_REGION]: 'regionDto',
    [PARTNER_INSTANCE_NOTES]: 'notes'
};

export const PARTNER_TENANTS_API_MAPPING: Map<string> = {
    [TENANT_NAME]: 'name',
    [TENANT_URL]: 'tenantUrl',
    [SECRET_KEY]: 'secretKey',
    [USERNAME]: 'username',
    [PASSWORD]: 'password'
};
