import { useEffect, useMemo, useRef, useState } from 'react';

import {
    CustomHeader,
    IconButton,
    MultiSelect,
    Pagination,
    ShowSkeleton,
    StyledMenuPaper,
    Sync,
    Table,
    TableNoData,
    TableSkeleton,
    Tooltip,
    constants
} from '@armis/armis-ui-library';
import { Box, Menu, PaperProps, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { AxiosResponse } from 'axios';
import moment from 'moment';
import { TOAST_ID } from 'src/constants/APIConstants';
import {
    ANALYTICS_LABEL,
    COLON,
    LAST_SYNC,
    MINUTES,
    PARTNER,
    PARTNERS,
    SEARCH_PARTNERS_PLACEHOLDER,
    SYNC,
    SYNCED_SUCCESS,
    SYNC_MODAL_TITLE,
    SYNC_PARTNER_WARNING
} from 'src/constants/LabelText';
import {
    AggregatedViewAPIMapping,
    createRelatedObject,
    DEFAULT_PAGE,
    DEFAULT_PAGESIZE,
    NO_DATA_TO_SHOW
} from 'src/constants/TableConstants';
import { aggregatedViewDef } from 'src/helpers/ColumnsConfig';
import {
    convertQueryObjectToParams,
    displayErrorMessage,
    getDirectionsAndProperties,
    showToast,
    TOAST_TYPE
} from 'src/helpers/utility';
import IsLoadingHOC from 'src/hoc/IsLoadingHoc';
import { useTable } from 'src/hooks/useTable';
import { Header } from 'src/pages/components/Header';
import { Modal } from 'src/pages/components/Modal';
import SearchBar from 'src/pages/components/SearchBar/SearchBar';
import { TableHeader } from 'src/pages/components/TableHeader';
import { WarningModalContainer } from 'src/pages/components/WarningModalContainer/WarningModalContainer';
import { getAggregationData } from 'src/services/api.service';
import { ChildRefProp, FilterItems, Map } from 'src/types/CommonTypes';

import { AggregatedViewData } from './AggregatedView.types';

const aggregatedViewSortOrder: Map<number> = {};
const aggregatedViewSortStatus: Map<string> = {};
const columnsFilterItems: FilterItems[] = [];

createRelatedObject(
    AggregatedViewAPIMapping,
    aggregatedViewSortOrder,
    aggregatedViewSortStatus,
    columnsFilterItems
);
const AggregatedViewComponent = () => {
    const {
        tableLoading,
        setTableLoading,
        columnSortOrder,
        columnSortStatus,
        modelOpen,
        setModalOpen,
        gridRef,
        filterItems,
        anchorEl,
        onSortChangedCall,
        handleMenuClick,
        handleMenuClose,
        onSelectionChanged,
        queryProperties,
        setQueryProperties
    } = useTable({
        sortOrderObj: aggregatedViewSortOrder,
        sortStatusObj: aggregatedViewSortStatus,
        columnsFilterItems
    });

    const theme = useTheme();

    const [rowData, setRowData] = useState<AggregatedViewData[]>([]);
    const [intervalTime, setIntervalTime] = useState<number | null>(null);
    const [totalRows, setTotalRows] = useState(0);
    const [lastSynced, setlastSynced] = useState(0);
    const paginationRef = useRef<ChildRefProp>();
    const firstRender = useRef(true);

    const defaultColDefs = useMemo(
        () => ({
            headerComponent: CustomHeader,
            headerComponentParams: {
                onSortChanged: onSortChangedCall,
                columnSortOrder,
                columnSortStatus
            },
            sortable: true,
            resizable: true,
            filter: false
        }),
        [columnSortOrder, onSortChangedCall, columnSortStatus]
    );

    const getParteners = (isRefresh: boolean = false) => {
        let totalElements = 0;
        setTableLoading(true);
        getAggregationData(
            convertQueryObjectToParams(queryProperties),
            isRefresh
        )
            .then((res: AxiosResponse) => {
                setRowData(res.data.partnerAggregationDto.content);
                totalElements = res.data.partnerAggregationDto.totalElements;
                const { expiryTime } = res.data;
                if (expiryTime && expiryTime >= new Date().getTime()) {
                    setIntervalTime(expiryTime - new Date().getTime());
                } else {
                    setIntervalTime(null);
                }
                setlastSynced(res.data.lastSynced);
                setTotalRows(totalElements);
                if (isRefresh) {
                    showToast(SYNCED_SUCCESS, TOAST_TYPE.SUCCESS, TOAST_ID);
                }
            })
            .catch(err => {
                displayErrorMessage(err);
                setRowData([]);
                setTotalRows(0);
            })
            .finally(() => {
                setTableLoading(false);
                if (
                    queryProperties.page === DEFAULT_PAGE &&
                    queryProperties.size === DEFAULT_PAGESIZE
                )
                    paginationRef.current?.resetPagination();
            });
    };

    useEffect(() => {
        let intervalId: any;
        if (intervalTime) {
            intervalId = setInterval(getParteners, intervalTime);
        }
        return () => {
            clearInterval(intervalId);
        };
    }, [intervalTime]);

    useEffect(() => {
        getParteners();
    }, [queryProperties, setTableLoading]);

    const searchPartnerContainer = useMemo(
        () => (
            <div>
                <SearchBar
                    onChange={newValue =>
                        setQueryProperties(prevValue => ({
                            ...prevValue,
                            searchBy: newValue,
                            page: DEFAULT_PAGE,
                            size: DEFAULT_PAGESIZE
                        }))
                    }
                    placeholder={SEARCH_PARTNERS_PLACEHOLDER}
                    searchValue={queryProperties.searchBy as string}
                />
            </div>
        ),
        [queryProperties.searchBy]
    );

    useEffect(() => {
        if (firstRender.current) {
            firstRender.current = false;
            return;
        }
        const { directions, properties } = getDirectionsAndProperties(
            columnSortOrder,
            columnSortStatus
        );
        setQueryProperties(prevValue => ({
            ...prevValue,
            directions,
            properties,
            page: DEFAULT_PAGE,
            size: DEFAULT_PAGESIZE
        }));
        paginationRef.current?.resetPagination();
    }, [columnSortStatus, columnSortOrder, setQueryProperties]);

    useEffect(() => {
        if (!gridRef.current?.api) return;
        if (rowData.length === 0 && !tableLoading && !firstRender.current) {
            setTimeout(() => {
                gridRef.current?.api.showNoRowsOverlay();
            }, 100);
        }
    }, [rowData, tableLoading, gridRef]);

    return (
        <>
            <Header title={ANALYTICS_LABEL} />
            <div className="control table">
                <TableHeader
                    childrenLeft={
                        <>
                            {!!lastSynced && (
                                <ShowSkeleton
                                    content={
                                        <Box sx={{ display: 'flex' }}>
                                            <Typography
                                                sx={{
                                                    color:
                                                        theme.palette.mode ===
                                                        'dark'
                                                            ? 'white'
                                                            : constants.COLOR_GENERAL_3
                                                }}
                                                variant="h5"
                                            >
                                                {LAST_SYNC}
                                                {COLON}&nbsp;
                                            </Typography>
                                            <Typography variant="h5">
                                                {moment(lastSynced)
                                                    .startOf(MINUTES)
                                                    .fromNow()}
                                            </Typography>
                                        </Box>
                                    }
                                    loading={tableLoading}
                                />
                            )}
                            {searchPartnerContainer}
                        </>
                    }
                    childrenRight={
                        <Tooltip
                            arrow
                            title={
                                <div className="tooltip-arrow-text">{SYNC}</div>
                            }
                        >
                            <span>
                                <IconButton
                                    aria-label={SYNC}
                                    className="Icon-Hover-Effect"
                                    disabled={tableLoading}
                                    onClick={() => setModalOpen(true)}
                                >
                                    <Sync />
                                </IconButton>
                            </span>
                        </Tooltip>
                    }
                    loading={tableLoading}
                    onColumnMenuClick={handleMenuClick}
                    title={`${totalRows} ${
                        totalRows === 1 ? PARTNER : PARTNERS
                    }`}
                />
                <Menu
                    anchorEl={anchorEl}
                    onClose={handleMenuClose}
                    open={Boolean(anchorEl)}
                    PaperProps={
                        {
                            component: StyledMenuPaper
                        } as Partial<PaperProps<'div', {}>> | undefined
                    }
                >
                    <MultiSelect
                        items={filterItems}
                        onSelectionChanged={onSelectionChanged}
                        showSelectAllOption
                    />
                </Menu>
                <Modal
                    displayBtn="all"
                    isModalOpen={modelOpen}
                    onCancel={() => setModalOpen(false)}
                    onSubmit={() => {
                        setModalOpen(false);
                        getParteners(true);
                    }}
                    submitBtnLabel={SYNC}
                    title={SYNC_MODAL_TITLE}
                >
                    <WarningModalContainer text={SYNC_PARTNER_WARNING} />
                </Modal>
                <Table
                    ref={gridRef}
                    columnDefs={aggregatedViewDef}
                    defaultColDef={defaultColDefs}
                    loadingOverlayComponent={TableSkeleton}
                    noRowsOverlayComponent={TableNoData}
                    noRowsOverlayComponentParams={{
                        content: NO_DATA_TO_SHOW
                    }}
                    rowData={rowData}
                    suppressNoRowsOverlay={firstRender.current}
                />
                {!!totalRows && totalRows > 0 && (
                    <Pagination
                        ref={paginationRef}
                        onPaginationChanged={(page, size) => {
                            setQueryProperties(prevValue => ({
                                ...prevValue,
                                page,
                                size
                            }));
                        }}
                        totalRowsCount={totalRows}
                    />
                )}
            </div>
        </>
    );
};

export const AggregatedView = IsLoadingHOC(AggregatedViewComponent);
