import React, { useState, useEffect } from 'react';

import {
    ArrowDown,
    Button,
    CheckBoxEmpty,
    CheckBoxFill,
    CheckBoxSemi,
    CheckBoxTreeGroup,
    CHECKBOX_STATUS,
    Duplicate,
    IconButton,
    IconDelete,
    IconPencil,
    IconUser,
    PermissionsRestricted,
    PrivilegeType,
    RoleExpansionPanel,
    Tooltip
} from '@armis/armis-ui-library';
import { Checkbox } from '@mui/material';
import { cloneDeep } from 'lodash';
import { useSelector } from 'react-redux';
import { Privileges, Resources } from 'src/constants/CommonConstants';
import {
    ASSIGNED,
    CLOSE,
    DISCARD,
    NO_USER,
    ROLE_NAME_ERROR_EMPTY,
    ROLE_NAME_ERROR_MAXLENGTH,
    ROLE_NAME_PLACEHOLDER,
    SAVE,
    USER
} from 'src/constants/LabelText';
import { isActionHasPermissions } from 'src/helpers/utility';
import { Validators } from 'src/helpers/Validators';
import { ProtectedAction } from 'src/pages/common/ProtectedAction';
import { Modal } from 'src/pages/components/Modal';
import {
    getIcon,
    LOW_PRIVILEGE,
    recursivelyUpdateChildStatus
} from 'src/pages/containers/RoleManagement/helpers';
import {
    StyledListItem,
    StyledTextBox
} from 'src/pages/containers/RoleManagement/RoleManagement.style';
import { RoleElementProps } from 'src/pages/containers/RoleManagement/RoleManagement.types';
import { selectUser } from 'src/store/slices/userSlice';
import { RoleResponse } from 'src/types/APIResponseTypes';

const ROLE_NAME_MAX_LENGTH = 100;

const checkEqualRecursively = (
    oldPrivileges: PrivilegeType[],
    newPrivileges: PrivilegeType[]
): boolean => {
    const isEqual = oldPrivileges.every((privilege, index) => {
        if (privilege.checkStatus !== newPrivileges[index].checkStatus) {
            return false;
        }
        return checkEqualRecursively(
            privilege.childrens || [],
            newPrivileges?.[index].childrens || []
        );
    });

    return isEqual;
};

export const RoleElement = ({
    role,
    isNewRole = false,
    newRoleOrCopyMode = false,
    isSelected = false,
    onRoleSave,
    onRoleSelection,
    onClickDelete,
    onClickDuplicate
}: RoleElementProps) => {
    const [isRowExpanded, setIsRowExpanded] = useState(isNewRole);
    const [isCheckBoxSelected, setIsCheckBoxSelected] = useState(isSelected);
    const [currentRole, setCurrentRole] = useState(role);
    const [roleNameError, setRoleNameError] = useState('');
    const [isUserModalOpened, setisUserModalOpened] = useState(false);
    const [isRoleEditMode, setIsRoleEditMode] = useState(isNewRole);
    const [allowSaveAndDiscard, setAllowSaveAndDiscard] = useState(false);

    const [showTooltip, setShowTooltip] = useState(false);

    const handleMouseEnter = (e: React.MouseEvent<HTMLSpanElement>) => {
        const span = e.currentTarget;
        if (span.offsetWidth < span.scrollWidth) {
            setShowTooltip(true);
        }
    };

    const handleMouseLeave = () => {
        setShowTooltip(false);
    };

    const currentUser = useSelector(selectUser);

    const excludePermissionCheckToNewRole = (requiredRoles: string[]) =>
        isNewRole ? [Privileges.create] : requiredRoles;

    useEffect(() => {
        if (!(isRoleEditMode || allowSaveAndDiscard)) {
            // update name of already selected role, if any.
            onRoleSelection?.(prevSelectedRoles =>
                prevSelectedRoles.map(sRole =>
                    sRole.id === role.id ? { ...sRole, name: role.name } : sRole
                )
            );
            setCurrentRole(role);
        }
    }, [role, allowSaveAndDiscard, isRoleEditMode, onRoleSelection]);

    useEffect(() => {
        if (!(isNewRole || isRowExpanded))
            setIsRoleEditMode(currentRole.name !== role.name);
    }, [isRowExpanded, isNewRole, currentRole.name, role.name]);

    useEffect(() => {
        if (!Validators.validateNotEmpty(currentRole.name)) {
            setRoleNameError(ROLE_NAME_ERROR_EMPTY);
        } else if (
            !Validators.validateMaxLength(
                currentRole.name,
                ROLE_NAME_MAX_LENGTH
            )
        ) {
            setRoleNameError(ROLE_NAME_ERROR_MAXLENGTH);
        } else {
            setRoleNameError('');
        }

        if (currentRole.name.trim() !== role.name) {
            setAllowSaveAndDiscard(true);
        } else {
            let areEqual = false;
            areEqual = currentRole.resources.every((resource, index) => {
                areEqual = checkEqualRecursively(
                    resource.privileges,
                    role.resources[index].privileges
                );
                return areEqual;
            });
            setAllowSaveAndDiscard(!areEqual);
        }
    }, [currentRole, role.name, role.resources]);

    const onChildChangeHandler = (
        childPrivilege: PrivilegeType,
        resourceIndex: number,
        privilegeIndex: number
    ) => {
        setCurrentRole(prevValue => ({
            ...prevValue,
            resources: prevValue.resources.map((resource, rIndex) => {
                if (rIndex === resourceIndex) {
                    const updatedResource = cloneDeep(resource);
                    updatedResource.privileges[privilegeIndex] = childPrivilege;

                    // If some child privilege is checked or indeterminate, make Read privilege checked.
                    if (
                        childPrivilege.checkStatus !== CHECKBOX_STATUS.UNCHECKED
                    ) {
                        const readPrivilegeIndex =
                            updatedResource.privileges.findIndex(
                                child => child.label === LOW_PRIVILEGE
                            );
                        if (readPrivilegeIndex !== -1) {
                            updatedResource.privileges[
                                readPrivilegeIndex
                            ].checkStatus = CHECKBOX_STATUS.CHECKED;
                        }
                    }

                    // If Read privilege is unchecked, uncheck all child privileges.
                    if (
                        childPrivilege.checkStatus ===
                            CHECKBOX_STATUS.UNCHECKED &&
                        childPrivilege.label === LOW_PRIVILEGE
                    ) {
                        recursivelyUpdateChildStatus(
                            updatedResource.privileges,
                            CHECKBOX_STATUS.UNCHECKED
                        );
                        updatedResource.icon = PermissionsRestricted;
                    } else {
                        updatedResource.icon = getIcon(
                            updatedResource.privileges
                        );
                    }

                    return updatedResource;
                }
                return resource;
            })
        }));
    };

    const usersAssigned = currentRole.userDetails?.length
        ? `${currentRole.userDetails?.length} ${USER.toLowerCase()}${
              currentRole.userDetails?.length === 1 ? '' : 's'
          }`
        : '';

    const onSaveRole = async () => {
        // Convert role structure to post api payload.
        let privilegesIds: string[] = [];
        const generatePrivilegesIds = (privileges: PrivilegeType[]) => {
            privileges.forEach(privilege => {
                if (
                    privilege.checkStatus === CHECKBOX_STATUS.CHECKED &&
                    !privilege.childrens?.length
                )
                    privilegesIds.push(privilege.id);
                generatePrivilegesIds(privilege.childrens || []);
            });
        };

        const rolePayload: RoleResponse = {
            ...currentRole,
            name: currentRole.name.trim(),
            resources: currentRole.resources.map(resource => {
                privilegesIds = [];
                generatePrivilegesIds(resource.privileges);
                return {
                    id: resource.id,
                    label: resource.label,
                    name: resource.name,
                    privilegeIds: privilegesIds
                };
            })
        };

        const isSuccess = await onRoleSave?.(
            rolePayload,
            {
                ...cloneDeep(currentRole),
                name: currentRole.name.trim()
            },
            isNewRole
        );

        if (!isNewRole) setAllowSaveAndDiscard(!isSuccess);
        if (isSuccess) setIsRoleEditMode(false);
    };

    const onClickDiscard = () => {
        setCurrentRole(prevValue => ({
            ...prevValue,
            name: role.name,
            resources: cloneDeep(role.resources)
        }));
    };

    const getBody = () => (
        <>
            <div />
            <div className="advanced-info">
                <div className="assigned-users">
                    <IconUser />
                    {usersAssigned ? (
                        <>
                            <a
                                className="control"
                                onClick={() => setisUserModalOpened(true)}
                            >
                                {usersAssigned}
                            </a>
                            <span> {` ${ASSIGNED}`} </span>
                        </>
                    ) : (
                        NO_USER
                    )}
                </div>
            </div>
            <div className="entities-checkboxes">
                {currentRole.resources.map((resource, rindex) => (
                    <div key={resource.id} className="tree-view-checkbox">
                        {resource.privileges.map((privilege, pIndex) => (
                            <CheckBoxTreeGroup
                                key={privilege.id}
                                onChange={childPrivilege =>
                                    onChildChangeHandler(
                                        childPrivilege,
                                        rindex,
                                        pIndex
                                    )
                                }
                                privilege={privilege}
                            />
                        ))}
                    </div>
                ))}
            </div>
        </>
    );

    const getHeader = () => (
        <>
            <div className="content">
                <div className="checkbox">
                    {!(role.isdefault || isNewRole) && (
                        <ProtectedAction
                            hasPermissions={[Privileges.delete]}
                            resource={Resources.roles}
                        >
                            <Checkbox
                                checked={isCheckBoxSelected}
                                checkedIcon={<CheckBoxFill />}
                                className="ternary-checkbox"
                                data-testid="role-select-cb"
                                icon={<CheckBoxEmpty />}
                                indeterminateIcon={<CheckBoxSemi />}
                                onChange={({ target: { checked } }) => {
                                    setIsCheckBoxSelected(checked);
                                    onRoleSelection?.(prevValue => {
                                        const updatedSelectedRoles =
                                            cloneDeep(prevValue);
                                        if (checked) {
                                            updatedSelectedRoles.push({
                                                name: role.name,
                                                id: currentRole.id
                                            });
                                        } else {
                                            const index =
                                                updatedSelectedRoles.findIndex(
                                                    selectedRole =>
                                                        selectedRole.id ===
                                                        currentRole.id
                                                );

                                            updatedSelectedRoles.splice(
                                                index,
                                                1
                                            );
                                        }
                                        return updatedSelectedRoles;
                                    });
                                }}
                                onClick={e => e.stopPropagation()}
                            />
                        </ProtectedAction>
                    )}
                </div>
                {isRoleEditMode &&
                isActionHasPermissions(
                    currentUser,
                    Resources.roles,
                    excludePermissionCheckToNewRole([Privileges.edit])
                ) ? (
                    <div className="info">
                        <StyledTextBox
                            autoFocus
                            error={!!roleNameError}
                            FormHelperTextProps={{
                                className: 'custom-helpertext'
                            }}
                            helperText={roleNameError}
                            InputProps={{
                                className: `control text-input small absolute has-symbol theme-8 ${
                                    roleNameError ? 'invalid' : ''
                                }`
                            }}
                            onChange={e =>
                                setCurrentRole(prevValue => ({
                                    ...prevValue,
                                    name: e.target.value
                                }))
                            }
                            onClick={e => {
                                e.stopPropagation();
                                setIsRowExpanded(true);
                            }}
                            placeholder={ROLE_NAME_PLACEHOLDER}
                            value={currentRole.name}
                            variant="outlined"
                        />
                    </div>
                ) : (
                    <div
                        className="info"
                        onClick={e => {
                            if (!role.isdefault && isRowExpanded) {
                                e.stopPropagation();
                                setIsRoleEditMode(true);
                            }
                        }}
                    >
                        <Tooltip
                            className="tooltip-arrow-text"
                            open={showTooltip}
                            title={
                                <div className="tooltip-arrow-text">
                                    {currentRole.name}
                                </div>
                            }
                        >
                            <span
                                className="role-name"
                                onMouseEnter={handleMouseEnter}
                                onMouseLeave={handleMouseLeave}
                            >
                                {currentRole.name}
                            </span>
                        </Tooltip>
                        {/* <span className="role-name">{currentRole.name}</span> */}
                        {!role.isdefault && isRowExpanded && (
                            <ProtectedAction
                                hasPermissions={[Privileges.edit]}
                                resource={Resources.roles}
                            >
                                <IconButton
                                    className="Icon-Hover-Effect"
                                    sx={{ paddingLeft: '7px' }}
                                >
                                    <IconPencil />
                                </IconButton>
                            </ProtectedAction>
                        )}
                    </div>
                )}
                <div className="entities-icons">
                    {currentRole.resources.map(item => (
                        <div key={item.id} className="entity">
                            <item.icon />
                            <div className="label">{item.label}</div>
                        </div>
                    ))}
                </div>
                <ProtectedAction
                    hasPermissions={excludePermissionCheckToNewRole([
                        Privileges.delete
                    ])}
                    resource={Resources.roles}
                >
                    <div className="action">
                        {!role.isdefault && (
                            <IconButton
                                className="Icon-Hover-Effect"
                                onClick={e => {
                                    e.stopPropagation();
                                    onClickDelete?.();
                                }}
                            >
                                <IconDelete />
                            </IconButton>
                        )}
                    </div>
                </ProtectedAction>
                <ProtectedAction
                    hasPermissions={[Privileges.create]}
                    resource={Resources.roles}
                >
                    <IconButton
                        className={newRoleOrCopyMode ? '' : 'Icon-Hover-Effect'}
                        disabled={newRoleOrCopyMode}
                        onClick={e => {
                            e.stopPropagation();
                            onClickDuplicate?.();
                        }}
                    >
                        <Duplicate />
                    </IconButton>
                </ProtectedAction>
            </div>
            <ArrowDown className={isRowExpanded ? 'open' : ''} />
        </>
    );

    const getFooter = () => (
        <ProtectedAction
            hasPermissions={excludePermissionCheckToNewRole([Privileges.edit])}
            resource={Resources.roles}
        >
            <>
                <span
                    className={`discard ${
                        allowSaveAndDiscard ? '' : 'disabled'
                    }`}
                    onClick={() => allowSaveAndDiscard && onClickDiscard()}
                >
                    {DISCARD}
                </span>
                <Button
                    color="primary"
                    disabled={
                        !!roleNameError || !(allowSaveAndDiscard || isNewRole)
                    }
                    onClick={onSaveRole}
                    variant="contained"
                >
                    {SAVE}
                </Button>
            </>
        </ProtectedAction>
    );

    return (
        <>
            <Modal
                displayBtn="submit"
                isModalOpen={isUserModalOpened}
                onCancel={() => setisUserModalOpened(false)}
                onSubmit={() => setisUserModalOpened(false)}
                submitBtnLabel={CLOSE}
                title={`${usersAssigned} ${ASSIGNED}`}
            >
                {currentRole.userDetails?.map(user => (
                    <StyledListItem key={user.username}>
                        {user.username}
                    </StyledListItem>
                ))}
            </Modal>
            <RoleExpansionPanel
                body={getBody()}
                footer={!role.isdefault && getFooter()}
                header={getHeader()}
                isExpanded={isRowExpanded}
                roleType="predefined"
                setIsExpanded={setIsRowExpanded}
            />
        </>
    );
};
