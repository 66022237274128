import React, { useRef, useState } from 'react';

import { IconPencil } from '@armis/armis-ui-library';
import moment, { MomentInput } from 'moment';
// import { Pencil } from 'src/assets/images';
import { LAST_CHANGED, NEVER_CHANGED } from 'src/constants/LabelText';

import {
    LabelContainer,
    StyledInput,
    // StyledImage,
    ChangeTime
} from './EditField.style';
import { EditFieldProps } from './EditField.types';

export const EditField = ({
    id,
    disabled = false,
    type = 'text',
    label,
    value,
    passwordChangeData,
    onChange,
    onLeaveField,
    onFieldClick
}: EditFieldProps) => {
    const [showPencilIcon, setShowPencilIcon] = useState(false);
    const inpRef = useRef<null | HTMLInputElement>(null);

    return (
        <tr>
            <LabelContainer>{label}</LabelContainer>
            <td
                onMouseEnter={() => setShowPencilIcon(true)}
                onMouseLeave={() => setShowPencilIcon(false)}
            >
                {showPencilIcon && !disabled && (
                    // <StyledImage alt="penicl" src={Pencil} />
                    <IconPencil />
                )}
                {disabled && id !== 'password' ? (
                    <span> {value} </span>
                ) : (
                    <StyledInput
                        ref={inpRef}
                        autoComplete="off"
                        disabled={disabled}
                        id={id}
                        onBlur={onLeaveField}
                        onChange={onChange}
                        onClick={() => {
                            if (type === 'password') {
                                inpRef.current?.blur();
                                onFieldClick?.();
                            }
                        }}
                        onKeyDown={e => {
                            if (e.key === 'Enter') onLeaveField();
                        }}
                        placeholder={label}
                        sx={{ width: id === 'password' ? '80px' : 'auto' }}
                        type={type}
                        value={value}
                    />
                )}
                {id === 'password' && !disabled && (
                    <ChangeTime>
                        {passwordChangeData
                            ? `${LAST_CHANGED} ${moment(
                                  passwordChangeData as MomentInput
                              ).format('MMM D, YYYY HH:mm')}`
                            : NEVER_CHANGED}
                    </ChangeTime>
                )}
            </td>
        </tr>
    );
};
