import { Button } from '@armis/armis-ui-library';
import { LOGIN_WITH_SSO } from 'src/constants/LabelText';
import { ButtonContainer } from 'src/pages/containers/Login/Login.style';
import { loginWithSAML2 } from 'src/services/api.service';

export const BasicAuth = () => (
    <ButtonContainer>
        <Button
            color="special"
            fullWidth
            href={loginWithSAML2()}
            style={{ backgroundColor: '#2ABAFF' }}
            variant="contained"
        >
            {LOGIN_WITH_SSO}
        </Button>
    </ButtonContainer>
);
