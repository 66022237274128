import React, { useEffect, useState } from 'react';

import { TextBox } from '@armis/armis-ui-library';
import { InputAdornment, Typography } from '@mui/material';
import { AxiosError } from 'axios';
import { IoIosClose } from 'react-icons/io';
import {
    CHANGE,
    CHANGE_PASSWORD,
    CHANGE_PASSWORD_MODAL_TEXT_1,
    PASSWORD_REGEX,
    PASSWORD_VALIDATE,
    RETYPE_PASSWORD,
    TYPE_PASSWORD
} from 'src/constants/LabelText';
import { Validators } from 'src/helpers/Validators';
import { Modal } from 'src/pages/components/Modal';
import { changePassword } from 'src/services/api.service';
import { ChangePasswordPayload } from 'src/types/APIPayloadTypes';
import { ErrorResponse } from 'src/types/APIResponseTypes';

import { StyledDiv } from './ChangePassword.style';
import { ChangePasswordProps } from './ChangePassword.types';

export const ChangePassword = ({
    password,
    newPassword,
    retypePassword,
    setNewPassword,
    setRetypePassword,
    onClickCancel,
    onSubmitSuccess,
    setIsLoading
}: ChangePasswordProps) => {
    const [errorMsg, setErrorMsg] = useState('');
    const [isError, setIsError] = useState(false);
    const [isDisabledSubmitBtn, setIsDisabledSubmitBtn] = useState(true);

    useEffect(() => {
        setErrorMsg('');
        if (newPassword === retypePassword && newPassword === '') {
            return;
        }
        if (newPassword !== retypePassword) {
            setIsError(true);
            setIsDisabledSubmitBtn(true);
        } else {
            const isValidated = Validators.validateAgainstFormat(
                newPassword,
                PASSWORD_REGEX
            );
            setIsError(!isValidated);
            if (!isValidated) {
                setErrorMsg(PASSWORD_VALIDATE);
            }
            setIsDisabledSubmitBtn(!isValidated);
        }
    }, [newPassword, retypePassword]);

    const onClickSubmitHandler = () => {
        const data = {
            newPassword: {
                value: newPassword
            },
            oldPassword: {
                value: password
            }
        };

        setIsLoading(true);

        changePassword(data as ChangePasswordPayload)
            .then(res => {
                onClickCancel();
                onSubmitSuccess(res.data);
            })
            .catch((err: AxiosError<ErrorResponse>) => {
                setIsError(true);
                setIsLoading(false);
                if (err.response) {
                    setErrorMsg(err.response?.data?.message || '');
                } else {
                    setErrorMsg(err.message);
                }
            });
    };

    const preventSpace = (e: React.KeyboardEvent<HTMLDivElement>) => {
        if (e.key === ' ') e.preventDefault();
    };

    return (
        <Modal
            isDisabledSubmitBtn={isDisabledSubmitBtn}
            isModalOpen
            onCancel={onClickCancel}
            onSubmit={onClickSubmitHandler}
            submitBtnLabel={CHANGE}
            title={CHANGE_PASSWORD}
        >
            <Typography gutterBottom sx={{ mb: '20px' }}>
                {CHANGE_PASSWORD_MODAL_TEXT_1}
            </Typography>
            <StyledDiv>
                <TextBox
                    error={isError}
                    fullWidth
                    helperText={errorMsg}
                    hiddenLabel
                    id="new-password-field"
                    InputProps={{
                        endAdornment: newPassword && (
                            <InputAdornment
                                onClick={() => setNewPassword('')}
                                position="end"
                                variant="outlined"
                            >
                                <IoIosClose />
                            </InputAdornment>
                        )
                    }}
                    onChange={e => setNewPassword(e.target.value)}
                    onKeyDown={preventSpace}
                    placeholder={TYPE_PASSWORD}
                    size="small"
                    type="password"
                    value={newPassword}
                    variant="outlined"
                />
                <TextBox
                    error={isError}
                    fullWidth
                    hiddenLabel
                    id="retype-password-field"
                    InputProps={{
                        endAdornment: retypePassword && (
                            <InputAdornment
                                onClick={() => setRetypePassword('')}
                                position="end"
                                variant="outlined"
                            >
                                <IoIosClose />
                            </InputAdornment>
                        )
                    }}
                    onChange={e => setRetypePassword(e.target.value)}
                    onKeyDown={preventSpace}
                    placeholder={RETYPE_PASSWORD}
                    size="small"
                    type="password"
                    value={retypePassword}
                    variant="outlined"
                />
            </StyledDiv>
        </Modal>
    );
};
