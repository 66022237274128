import { createSlice } from '@reduxjs/toolkit';
import type { RootState } from 'src/store/store';

// This will be used to disaply global error messages using pop-up.
export const themeSlice = createSlice({
    name: 'theme',
    initialState: {
        themeMode: 'light',
    },
    reducers: {
        setTheme: (state, action) => {
            state.themeMode = action.payload;
        }
    }
});

export const selectThemeMode = (state: RootState) => state.theme.themeMode;

export const { setTheme } = themeSlice.actions;

export default themeSlice.reducer;
