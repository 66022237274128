import { createSlice } from '@reduxjs/toolkit';
import type { RootState } from 'src/store/store';
import { User } from 'src/types/APIResponseTypes';

interface InitialStateType {
    userData: User | null;
}

const initialState: InitialStateType = {
    userData: null
};

export const userSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {
        setUser: (state, action) => {
            state.userData = action.payload;
        }
    }
});

export const selectUser = (state: RootState) => state.user.userData as User;
export const selectUserLoggnedInStatus = (state: RootState) =>
    state.user.isUserLoggedIn;

// Action creators are generated for each case reducer function
export const { setUser } = userSlice.actions;

export default userSlice.reducer;
