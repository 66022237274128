import { configureStore, Store } from '@reduxjs/toolkit';

import {
    roleReducer,
    errorReducer,
    userReducer,
    regionReducer,
    themeReducer
} from './slices';

export const createStore = (props?: any) =>
    configureStore({
        reducer: {
            user: userReducer,
            error: errorReducer,
            role: roleReducer,
            region: regionReducer,
            theme: themeReducer,
        },
        ...props
    });

export const store: Store = createStore();
export type RootState = ReturnType<typeof store.getState>;
