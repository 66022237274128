import React, { useState, useEffect, useCallback } from 'react';

import { Loader } from '@armis/armis-ui-library';
import { Box } from '@mui/material';
import { StyledLoaderContainer } from 'src/helpers/Common.style';

const IsLoadingHOC = (Component: React.ComponentType<any>) =>
    function HOC(props: any) {
        const [loadingConfig, setloadingConfig] = useState({
            loading: false,
            isFullPage: false
        });
        const [firstMount, setfirstMount] = useState(true);

        useEffect(() => {
            setfirstMount(false);
        }, []);

        const setLoadingState = useCallback(
            (
                isComponentLoading: boolean,
                needFullPageLoading: boolean = false
            ) => {
                setloadingConfig({
                    loading: isComponentLoading,
                    isFullPage: needFullPageLoading
                });
            },
            []
        );
        let loader: string | JSX.Element = '';
        if (loadingConfig.loading && loadingConfig.isFullPage) {
            loader = (
                <Box
                    sx={{
                        display: 'flex',
                        padding: '20px',
                        justifyContent: 'center',
                        boxSizing: 'content-box'
                    }}
                >
                    <Loader
                        fade={false}
                        loaderType={{ name: 'spinner', size: 12 }}
                        show={loadingConfig.loading}
                    >
                        <div className="circle" data-testid="loading-circle" />
                    </Loader>
                </Box>
            );
        } else if (loadingConfig.loading) {
            loader = (
                <Loader
                    fade
                    loaderPosition={{ left: 90 }}
                    loaderType={{ name: 'dots' }}
                    show
                >
                    <div className="dots" data-testid="loading-dots" />
                </Loader>
            );
        }
        return (
            <>
                {loader}
                <StyledLoaderContainer
                    isHidden={
                        firstMount ||
                        (loadingConfig.loading && loadingConfig.isFullPage)
                    }
                >
                    <Component
                        {...props}
                        loadingConfig={loadingConfig}
                        setIsLoading={setLoadingState}
                    />
                </StyledLoaderContainer>
            </>
        );
    };

export default IsLoadingHOC;
