import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios';
import { TOAST_ID } from 'src/constants/APIConstants';
import { SESSION_EXPIRED } from 'src/constants/LabelText';
import { HOME, LOGIN } from 'src/constants/RouteConstants';
import { TOAST_TYPE, showToast } from 'src/helpers/utility';
import { ErrorResponse } from 'src/types/APIResponseTypes';

const { origin, hostname, port } = window.location;

let API_BASE_URL = `http://${hostname}:${port}`;
if (process.env.NODE_ENV === 'production') {
    API_BASE_URL = `${origin}/api`;
}

export const axiosController = {
    controller: new AbortController()
};

const axiosConfig = {
    baseURL: API_BASE_URL,
    timeout: 30 * 1000
};

// withCredentials indicates whether or not cross-site Access-Control requests should be made using credentials (e.g. Cookies).
axios.defaults.withCredentials = true;

const axiosInstance = axios.create(axiosConfig);

const onRequest = (request: AxiosRequestConfig): AxiosRequestConfig => {
    request.signal = axiosController.controller.signal;
    request.signal.onabort = () => {
        axiosController.controller = new AbortController();
    };
    return request;
};

const onRequestError = (error: AxiosError): Promise<AxiosError> =>
    Promise.reject(error);
const onResponse = (response: AxiosResponse): AxiosResponse => response;
const onResponseError = (
    error: AxiosError<ErrorResponse>
): Promise<AxiosError> => {
    const currentRoute = window.location.pathname;
    const excludeRoutes = [LOGIN];

    if (
        error.response?.status === 401 &&
        !excludeRoutes.includes(currentRoute)
    ) {
        showToast(SESSION_EXPIRED, TOAST_TYPE.ERROR, TOAST_ID);
        localStorage.setItem('relayState', currentRoute);
        window.location.href = `${LOGIN}${
            currentRoute !== HOME
                ? `?r=${encodeURIComponent(currentRoute)}`
                : ''
        }`;
        return Promise.reject();
    }

    return Promise.reject(error);
};

axiosInstance.interceptors.request.use(onRequest, onRequestError);
axiosInstance.interceptors.response.use(onResponse, onResponseError);

export { axiosInstance };
