import { createSlice } from '@reduxjs/toolkit';
import type { RootState } from 'src/store/store';

// This will be used to disaply global error messages using pop-up.
export const errorSlice = createSlice({
    name: 'error',
    initialState: {
        isError: false,
        errorMsg: ''
    },
    reducers: {
        setError: (state, action) => {
            state.isError = true;
            state.errorMsg = action.payload;
        },
        removeError: state => {
            state.isError = false;
            state.errorMsg = '';
        }
    }
});

export const selectErrorStatus = (state: RootState) => state.error.isError;
export const selectErrorMsg = (state: RootState) => state.error.errorMsg;

export const { setError, removeError } = errorSlice.actions;

export default errorSlice.reducer;
