import { createSlice } from '@reduxjs/toolkit';
import type { RootState } from 'src/store/store';
import { RoleStructureResponse } from 'src/types/APIResponseTypes';

interface InitialStateType {
    roleStructure: RoleStructureResponse | null;
}

const initialState: InitialStateType = {
    roleStructure: null
};

export const roleSlice = createSlice({
    name: 'role',
    initialState,
    reducers: {
        setRoleStructure: (state, action) => {
            state.roleStructure = action.payload;
        }
    }
});

export const selectRoleStructure = (state: RootState) => state.role.roleStructure as RoleStructureResponse | null;

export const { setRoleStructure } = roleSlice.actions;

export default roleSlice.reducer;