import React, { useEffect, useMemo, useRef, useState } from 'react';

import {
    Button,
    CustomHeader,
    IconButton,
    Pagination,
    ShowSkeleton,
    Table,
    TableNoData,
    TableSkeleton,
    IconPencil,
    Breadcrumbs,
    MultiSelect,
    StyledMenuPaper,
    Tooltip,
    WhitePlus
} from '@armis/armis-ui-library';
import { Menu, PaperProps, Typography } from '@mui/material';
import { ColDef, ICellRendererParams } from 'ag-grid-community';
import { AxiosError, AxiosResponse } from 'axios';
import { TbPlugOff } from 'react-icons/tb';
import { useSelector } from 'react-redux';
import { useParams, Link } from 'react-router-dom';
import { TOAST_ID } from 'src/constants/APIConstants';
import { Privileges, Resources } from 'src/constants/CommonConstants';
import {
    ADD,
    ASSOCIATE_TENANT,
    DIASSOCIATE,
    EDIT,
    PARTNERS,
    TENANT,
    TENANTS,
    TENANTS_SEARCH,
    TENANT_DIASSOCIATE_SUCCESS,
    TENANT_DIASSOCIATE_WARNING,
    TENANT_DIASSOCIATE_WARNING_TITLE
} from 'src/constants/LabelText';
import { PARTNERS as PARTNERS_ROUTE } from 'src/constants/RouteConstants';
import {
    createRelatedObject,
    DEFAULT_PAGE,
    DEFAULT_PAGESIZE,
    NO_DATA_TO_SHOW,
    partnerTenantsAPIMapping
} from 'src/constants/TableConstants';
import { partnerTenantsColumnDef } from 'src/helpers/ColumnsConfig';
import {
    convertQueryObjectToParams,
    displayErrorMessage,
    getDirectionsAndProperties,
    isActionHasPermissions,
    showToast,
    TOAST_TYPE
} from 'src/helpers/utility';
import IsLoadingHOC from 'src/hoc/IsLoadingHoc';
import { useTable } from 'src/hooks/useTable';
import { ProtectedAction } from 'src/pages/common/ProtectedAction';
import { Header } from 'src/pages/components/Header';
import { Modal } from 'src/pages/components/Modal';
import SearchBar from 'src/pages/components/SearchBar/SearchBar';
import { TableHeader } from 'src/pages/components/TableHeader';
import { WarningModalContainer } from 'src/pages/components/WarningModalContainer/WarningModalContainer';
import { AssociateTenant } from 'src/pages/containers/PartnerTenants/AssociateTenant/AssociateTenant';
import { PartnerTenantsProps } from 'src/pages/containers/PartnerTenants/PartnerTenants.types';
import {
    deleteTenantByID,
    getPartnerByID,
    getPartnerTenants
} from 'src/services/api.service';
import { selectUser } from 'src/store/slices/userSlice';
import {
    ErrorResponse,
    GenericResponseData,
    PartnersType,
    PartnerTenantsType
} from 'src/types/APIResponseTypes';
import { ChildRefProp, FilterItems, Map } from 'src/types/CommonTypes';

const partnerTenantsSortOrder: Map<number> = {};
const partnerTenantsSortStatus: Map<string> = {};
const columnsFilterItems: FilterItems[] = [];
createRelatedObject(
    partnerTenantsAPIMapping,
    partnerTenantsSortOrder,
    partnerTenantsSortStatus,
    columnsFilterItems
);

const PartnerTenantsComp = ({
    setIsLoading,
    loadingConfig
}: PartnerTenantsProps) => {
    const [partnerDetails, setPartnerDetails] = useState<PartnersType | null>(
        null
    );
    const [currentMenuItem, setCurrentMenuItem] =
        useState<PartnerTenantsType | null>(null);
    const firstRender = useRef(true);
    const { partnerId } = useParams();
    const paginationRef = useRef<ChildRefProp>();
    const [totalRows, setTotalRows] = useState<number>(0);
    const [rowData, setRowData] = useState<PartnerTenantsType[]>([]);
    const [modalMode, setModalMode] = useState(ADD);
    const {
        tableLoading,
        setTableLoading,
        columnSortOrder,
        columnSortStatus,
        gridRef,
        filterItems,
        onSortChangedCall,
        handleMenuClick,
        handleMenuClose,
        modelOpen,
        setModalOpen,
        anchorEl,
        onSelectionChanged,
        queryProperties,
        setQueryProperties
    } = useTable({
        sortOrderObj: partnerTenantsSortOrder,
        sortStatusObj: partnerTenantsSortStatus,
        columnsFilterItems
    });
    const [warningModal, setWarningModal] = useState(false);
    const currentUser = useSelector(selectUser);

    const isArmisUser = currentUser.username.includes('@armis.com');

    const columnConfig = useMemo(
        () => [
            ...partnerTenantsColumnDef,
            {
                field: 'partnerTenantsActions',
                headerName: 'Actions',
                pinned: 'right',
                suppressAutoSize: true,
                suppressSizeToFit: true,
                suppressMovable: true,
                initialWidth: 40,
                // eslint-disable-next-line react/no-unstable-nested-components
                cellRenderer: (
                    params: ICellRendererParams<PartnerTenantsType>
                ) => (
                    <>
                        <IconButton
                            aria-label={EDIT}
                            className="Icon-Hover-Effect"
                            disabled={
                                !isActionHasPermissions(
                                    currentUser,
                                    Resources.tenant,
                                    [Privileges.edit]
                                )
                            }
                            onClick={() => {
                                setCurrentMenuItem(params.data!);
                                setModalMode(EDIT);
                            }}
                            sx={{ marginRight: '10px' }}
                        >
                            <IconPencil height={16} />
                        </IconButton>
                        <Tooltip
                            arrow
                            title={
                                <div className="tooltip-arrow-text">
                                    {DIASSOCIATE}
                                </div>
                            }
                        >
                            <span>
                                <IconButton
                                    aria-label={DIASSOCIATE}
                                    className="Icon-Hover-Effect"
                                    disabled={
                                        !isActionHasPermissions(
                                            currentUser,
                                            Resources.tenant,
                                            [Privileges.delete]
                                        )
                                    }
                                    onClick={() => {
                                        setCurrentMenuItem(params.data!);
                                        setWarningModal(true);
                                    }}
                                >
                                    <TbPlugOff />
                                </IconButton>
                            </span>
                        </Tooltip>
                    </>
                ),
                sortable: false
            } as ColDef
        ],
        []
    );

    const defaultColDefs = useMemo(
        () => ({
            headerComponent: CustomHeader,
            headerComponentParams: {
                onSortChanged: onSortChangedCall,
                columnSortOrder,
                columnSortStatus
            },
            sortable: true,
            resizable: true
        }),
        [columnSortOrder, columnSortStatus]
    );

    useEffect(() => {
        if (!gridRef.current?.api) return;
        if (rowData.length === 0 && !tableLoading && !firstRender.current) {
            setTimeout(() => {
                gridRef.current?.api.showNoRowsOverlay();
            }, 100);
        }
    }, [rowData, tableLoading, gridRef]);

    useEffect(() => {
        setIsLoading(true, true);
        getPartnerByID(partnerId!)
            .then((res: AxiosResponse<PartnersType>) => {
                setPartnerDetails(res.data);
            })
            .catch((err: AxiosError<ErrorResponse>) => {
                displayErrorMessage(err);
            })
            .finally(() => {
                setIsLoading(false);
            });
    }, []);

    const getAllTenants = () => {
        setTableLoading(true);
        getPartnerTenants(
            convertQueryObjectToParams(queryProperties),
            partnerId!
        )
            .then(
                (
                    res: AxiosResponse<GenericResponseData<PartnerTenantsType>>
                ) => {
                    setTotalRows(res.data.totalElements);
                    setRowData(res.data.content);
                }
            )
            .catch((err: AxiosError<ErrorResponse>) => {
                displayErrorMessage(err);
                setRowData([]);
                setTotalRows(0);
            })
            .finally(() => {
                setTableLoading(false);
                if (
                    queryProperties.page === DEFAULT_PAGE &&
                    queryProperties.size === DEFAULT_PAGESIZE
                )
                    paginationRef.current?.resetPagination();
            });
    };

    const disAssociateTenant = () => {
        setIsLoading(true);
        deleteTenantByID(currentMenuItem?.id!)
            .then(() => {
                getAllTenants();
                const successMsg = TENANT_DIASSOCIATE_SUCCESS.replace(
                    '%s',
                    currentMenuItem?.name!
                );
                showToast(successMsg, TOAST_TYPE.SUCCESS, TOAST_ID);
            })
            .catch((err: AxiosError<ErrorResponse>) => {
                displayErrorMessage(err);
            })
            .finally(() => {
                setIsLoading(false);
            });
    };

    useEffect(() => {
        getAllTenants();
    }, [queryProperties]);

    useEffect(() => {
        if (firstRender.current) {
            firstRender.current = false;
            return;
        }
        const { directions, properties } = getDirectionsAndProperties(
            columnSortOrder,
            columnSortStatus
        );
        setQueryProperties(prevValue => ({
            ...prevValue,
            directions,
            properties,
            page: DEFAULT_PAGE,
            size: DEFAULT_PAGESIZE
        }));
    }, [columnSortOrder, columnSortStatus]);

    return (
        <>
            <Header title="">
                <Breadcrumbs>
                    <Link className="parent-link" to={PARTNERS_ROUTE}>
                        {PARTNERS}
                    </Link>
                    <Typography
                        style={{ fontFamily: 'Proxima Nova Bld' }}
                        variant="h4"
                    >
                        <ShowSkeleton
                            content={
                                <span style={{ fontWeight: 'bolder' }}>
                                    {partnerDetails?.name}
                                </span>
                            }
                            loading={loadingConfig.loading}
                        />
                    </Typography>
                </Breadcrumbs>
                <ProtectedAction
                    hasPermissions={[Privileges.create]}
                    resource={Resources.tenant}
                >
                    <Button
                        className="header-add-button"
                        color="primary"
                        onClick={() => setModalOpen(true)}
                        startIcon={<WhitePlus />}
                        variant="contained"
                    >
                        {ASSOCIATE_TENANT}
                    </Button>
                </ProtectedAction>
            </Header>
            <div className="control table">
                <TableHeader
                    childrenLeft={
                        <SearchBar
                            onChange={newValue => {
                                setQueryProperties(prevValue => ({
                                    ...prevValue,
                                    searchBy: newValue,
                                    page: DEFAULT_PAGE,
                                    size: DEFAULT_PAGESIZE
                                }));
                            }}
                            placeholder={TENANTS_SEARCH}
                            searchValue={queryProperties.searchBy}
                        />
                    }
                    loading={tableLoading}
                    onColumnMenuClick={handleMenuClick}
                    title={`${totalRows} ${totalRows === 1 ? TENANT : TENANTS}`}
                />
                <Menu
                    anchorEl={anchorEl}
                    onClose={handleMenuClose}
                    open={Boolean(anchorEl)}
                    PaperProps={
                        {
                            component: StyledMenuPaper
                        } as Partial<PaperProps<'div', {}>> | undefined
                    }
                >
                    <MultiSelect
                        items={filterItems}
                        onSelectionChanged={onSelectionChanged}
                        showSelectAllOption
                    />
                </Menu>
                <Modal
                    displayBtn="all"
                    isModalOpen={warningModal}
                    onCancel={() => setWarningModal(false)}
                    onSubmit={() => {
                        setWarningModal(false);
                        disAssociateTenant();
                    }}
                    submitBtnLabel={DIASSOCIATE}
                    title={TENANT_DIASSOCIATE_WARNING_TITLE.replace(
                        '%s',
                        currentMenuItem?.name!
                    )}
                >
                    <WarningModalContainer
                        text={TENANT_DIASSOCIATE_WARNING.replace(
                            '%s',
                            currentMenuItem?.name!
                        )}
                    />
                </Modal>
                <AssociateTenant
                    currentMenuItem={currentMenuItem}
                    getAllTenants={getAllTenants}
                    modalMode={modalMode}
                    modelOpen={modelOpen}
                    partnerDetails={partnerDetails}
                    setIsLoading={setIsLoading}
                    setModalMode={setModalMode}
                    setModalOpen={setModalOpen}
                />
                <Table
                    ref={gridRef}
                    columnDefs={columnConfig}
                    context={{ isArmisUser }}
                    defaultColDef={defaultColDefs}
                    loadingOverlayComponent={TableSkeleton}
                    noRowsOverlayComponent={TableNoData}
                    noRowsOverlayComponentParams={{
                        content: NO_DATA_TO_SHOW
                    }}
                    rowData={rowData}
                    suppressNoRowsOverlay={firstRender.current}
                />
                {!!totalRows && totalRows > 0 && (
                    <Pagination
                        ref={paginationRef}
                        onPaginationChanged={(page, size) => {
                            setQueryProperties(prevValue => ({
                                ...prevValue,
                                page,
                                size
                            }));
                        }}
                        totalRowsCount={totalRows}
                    />
                )}
            </div>
        </>
    );
};

export const PartnerTenants = IsLoadingHOC(PartnerTenantsComp);
