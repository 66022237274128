import React, { useRef, useState, useMemo, useEffect } from 'react';

import {
    IconButton,
    Table,
    CustomHeader,
    Pagination,
    StyledMenuPaper,
    MultiSelect,
    TableSkeleton,
    Export,
    CHECKBOX_STATUS,
    TableNoData
} from '@armis/armis-ui-library';
import { Menu, PaperProps } from '@mui/material';
import { AxiosError } from 'axios';
import moment from 'moment';
import {
    AUDIT_LOG_HEADER,
    AUDIT_LOG_TITLE,
    EXPORT,
    EXPORT_MODAL_TITLE,
    EXPORT_MODAL_CONTENT
} from 'src/constants/LabelText';
import {
    auditLogAPIMapping,
    createRelatedObject,
    DEFAULT_PAGE,
    DEFAULT_PAGESIZE,
    NO_DATA_TO_SHOW
} from 'src/constants/TableConstants';
import { auditLogsColumnDef } from 'src/helpers/ColumnsConfig';
import {
    convertQueryObjectToParams,
    displayErrorMessage,
    getDirectionsAndProperties
} from 'src/helpers/utility';
import IsLoadingHOC from 'src/hoc/IsLoadingHoc';
import { useTable } from 'src/hooks/useTable';
import { ExportModalContainer } from 'src/pages/components/ExportModalContainer/ExportModalContainer';
import { Header } from 'src/pages/components/Header';
import { Modal } from 'src/pages/components/Modal';
import { TableHeader } from 'src/pages/components/TableHeader';
import {
    exportAuditLog,
    getAuditLogsData,
    getAuditLogsFilterColumns
} from 'src/services/api.service';
import { ExportAuditLogPayload } from 'src/types/APIPayloadTypes';
import { ErrorResponse } from 'src/types/APIResponseTypes';
import { Map, FilterItems, ChildRefProp } from 'src/types/CommonTypes';

import { AuditLogData, AuditLogProps } from './AuditLog.types';

const auditLogsSortOrder: Map<number> = {};
const auditLogsSortStatus: Map<string> = {};
const columnsFilterItems: FilterItems[] = [];
const columnFilterObj: { [key: string]: FilterItems[] } = {
    username: [],
    action: []
};
const queryPropertiesObj: { [key: string]: string } = {
    username: '',
    action: '',
    filterByStartDate: '',
    filterByEndDate: ''
};
createRelatedObject(
    auditLogAPIMapping,
    auditLogsSortOrder,
    auditLogsSortStatus,
    columnsFilterItems
);

const AuditLogComponent = ({ setIsLoading }: AuditLogProps) => {
    let exportColumnData = {};
    let exportoutputFormatData = '';
    const {
        tableLoading,
        setTableLoading,
        columnSortOrder,
        columnSortStatus,
        gridRef,
        filterItems,
        anchorEl,
        onSortChangedCall,
        handleMenuClick,
        handleMenuClose,
        modelOpen,
        setModalOpen,
        onSelectionChanged,
        onFilterChanged,
        queryProperties,
        columnFilterData,
        setColumnFilterData,
        setQueryProperties
    } = useTable({
        sortOrderObj: auditLogsSortOrder,
        sortStatusObj: auditLogsSortStatus,
        columnsFilterItems,
        columnFilterObj,
        queryPropertiesObj
    });
    const [rowData, setRowData] = useState<AuditLogData[]>([]);
    const [totalRows, setTotalRows] = useState(0);
    const [defaultColumnValue, setDefaultColumnValue] = useState(['All']);
    const paginationRef = useRef<ChildRefProp>();
    const firstRender = useRef(true);

    const defaultColDefs = useMemo(
        () => ({
            headerComponent: CustomHeader,
            headerComponentParams: {
                onSortChanged: onSortChangedCall,
                onFilterChanged: (
                    columnName: string,
                    selectedItems: FilterItems[],
                    dates?: Date[],
                    filterType = 'dropdown'
                ) => {
                    let filterDates = {};
                    if (filterType === 'date' && dates) {
                        if (dates[0] && dates[1])
                            filterDates = {
                                filterByStartDate:
                                    moment(dates[0]).unix() * 1000,
                                filterByEndDate:
                                    moment(dates[1]).endOf('D').unix() * 1000
                            };
                    }

                    onFilterChanged(
                        columnName,
                        selectedItems,
                        filterDates,
                        filterType
                    );
                    paginationRef.current?.resetPagination();
                },
                columnSortOrder,
                columnSortStatus,
                getDateFilterValue: () => ({
                    createdon: [
                        (queryProperties as any).filterByStartDate,
                        (queryProperties as any).filterByEndDate
                    ]
                })
            },
            resizable: true,
            filter: false,
            filterParams: columnFilterData
        }),
        [
            columnSortOrder,
            onSortChangedCall,
            columnFilterData,
            onFilterChanged,
            columnSortStatus,
            (queryProperties as any).filterByStartDate,
            (queryProperties as any).filterByEndDate
        ]
    );

    const columnsData = (data: {}) => {
        exportColumnData = data;
    };
    const outputFormatData = (data: string) => {
        exportoutputFormatData = data;
    };
    const exportHandler = () => {
        setIsLoading(true);
        if (
            Object.keys(exportColumnData).length === 0 &&
            exportColumnData.constructor === Object
        ) {
            const columnsId = filterItems.map(
                element =>
                    element.checkStatus === CHECKBOX_STATUS.CHECKED && {
                        [element.label]: element.id
                    }
            );
            exportColumnData = Object.assign({}, ...columnsId);
        }

        const exportedData: ExportAuditLogPayload = {
            outputFormat: exportoutputFormatData,
            includedColumnNameAndLabels: exportColumnData
        };
        exportAuditLog(
            exportedData,
            convertQueryObjectToParams(queryProperties)
        )
            .then(response => {
                setIsLoading(false);
                const url = window.URL.createObjectURL(
                    new Blob([response.data])
                );
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute(
                    'download',
                    `Audit Logs.${exportoutputFormatData}`
                );
                document.body.appendChild(link);
                link.click();
            })
            .catch((err: AxiosError<ErrorResponse>) => {
                setIsLoading(false);
                displayErrorMessage(err);
            });
    };

    useEffect(() => {
        const doApiCall = async () => {
            setTableLoading(true);
            try {
                if (
                    columnFilterData.username.length === 0 ||
                    columnFilterData.action.length === 0
                ) {
                    const columnData = await getAuditLogsFilterColumns();
                    setColumnFilterData({
                        username: columnData.data.username.map(
                            (uname: any) => ({
                                id: uname,
                                label: uname,
                                labelRender: <span>{uname}</span>,
                                checkStatus: CHECKBOX_STATUS.CHECKED
                            })
                        ),
                        action: columnData.data.action.map((action: any) => ({
                            id: action,
                            label: action,
                            labelRender: <span>{action}</span>,
                            checkStatus: CHECKBOX_STATUS.CHECKED
                        }))
                    });
                }
                const res = await getAuditLogsData(
                    convertQueryObjectToParams(queryProperties)
                );
                const { data } = res;
                setRowData(data.content);
                setTotalRows(data.totalElements);
            } catch (err: any) {
                displayErrorMessage(err);
                setColumnFilterData({ username: [], action: [] });
                setRowData([]);
                setTotalRows(0);
            } finally {
                setTableLoading(false);
            }
        };
        doApiCall();
    }, [queryProperties, setTableLoading, setColumnFilterData]);

    useEffect(() => {
        if (firstRender.current) {
            firstRender.current = false;
            return;
        }
        const { directions, properties } = getDirectionsAndProperties(
            columnSortOrder,
            columnSortStatus
        );
        setQueryProperties(prevValue => ({
            ...prevValue,
            directions,
            properties,
            page: DEFAULT_PAGE,
            size: DEFAULT_PAGESIZE
        }));
        paginationRef.current?.resetPagination();
    }, [columnSortStatus, columnSortOrder, setQueryProperties]);

    useEffect(() => {
        if (!gridRef.current?.api) return;
        if (rowData.length === 0 && !tableLoading && !firstRender.current) {
            setTimeout(() => {
                gridRef.current?.api.showNoRowsOverlay();
            }, 100);
        }
    }, [rowData, tableLoading, gridRef]);
    return (
        <>
            <div style={{ marginTop: '15px' }}>
                <Header title={AUDIT_LOG_HEADER} />
            </div>
            <div className="control table">
                <TableHeader
                    childrenRight={
                        <IconButton
                            aria-label={EXPORT}
                            className="Icon-Hover-Effect"
                            onClick={() => setModalOpen(true)}
                            style={{ marginTop: '5px' }}
                        >
                            <Export />
                        </IconButton>
                    }
                    loading={tableLoading}
                    onColumnMenuClick={handleMenuClick}
                    title={`${totalRows} ${AUDIT_LOG_TITLE}`}
                />
                <Menu
                    anchorEl={anchorEl}
                    onClose={handleMenuClose}
                    open={Boolean(anchorEl)}
                    PaperProps={
                        {
                            component: StyledMenuPaper
                        } as Partial<PaperProps<'div', {}>> | undefined
                    }
                >
                    <MultiSelect
                        items={filterItems}
                        onSelectionChanged={items => {
                            onSelectionChanged(items);
                            setDefaultColumnValue(
                                items.length === filterItems.length
                                    ? ['All']
                                    : [`${items.length} Selected`]
                            );
                        }}
                        showSelectAllOption
                    />
                </Menu>
                <Modal
                    displayBtn="all"
                    isModalOpen={modelOpen}
                    onCancel={() => setModalOpen(false)}
                    onSubmit={() => {
                        exportHandler();
                        setModalOpen(false);
                    }}
                    submitBtnLabel={EXPORT}
                    title={EXPORT_MODAL_TITLE}
                >
                    <ExportModalContainer
                        columnsToBeExported={columnsData}
                        defaultValue={defaultColumnValue}
                        items={filterItems}
                        noOfAuditLogs={totalRows}
                        outputFormat={outputFormatData}
                        text={EXPORT_MODAL_CONTENT}
                    />
                </Modal>
                <Table
                    ref={gridRef}
                    columnDefs={auditLogsColumnDef}
                    defaultColDef={defaultColDefs}
                    loadingOverlayComponent={TableSkeleton}
                    noRowsOverlayComponent={TableNoData}
                    noRowsOverlayComponentParams={{
                        content: NO_DATA_TO_SHOW
                    }}
                    rowData={rowData}
                    suppressNoRowsOverlay={firstRender.current}
                />
                {!!totalRows && totalRows > 0 && (
                    <Pagination
                        ref={paginationRef}
                        onPaginationChanged={(page, size) => {
                            setQueryProperties(prevValue => ({
                                ...prevValue,
                                page,
                                size
                            }));
                        }}
                        totalRowsCount={totalRows}
                    />
                )}
            </div>
        </>
    );
};

export const AuditLog = IsLoadingHOC(AuditLogComponent);
