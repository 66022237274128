import React, { useTransition } from 'react';

import { useSelector } from 'react-redux';
import {
    ADD_QUERY,
    DELETE,
    DELETE_QUERY,
    EDIT_QUERY,
    QUERY_DELETE_WARNING,
    SUBMIT
} from 'src/constants/LabelText';
import { onFieldChangeHandler, validateFormFields } from 'src/helpers/utility';
import { Modal } from 'src/pages/components/Modal';
import { ModalField } from 'src/pages/components/ModalField/ModalField';
import { WarningModalContainer } from 'src/pages/components/WarningModalContainer/WarningModalContainer';
import { selectSelectedRegion } from 'src/store/slices/regionSlice';
import { ModalFieldType } from 'src/types/CommonTypes';

import { ASQ_QUERY_MAPPING, actionFormMetaData } from './constants';
import { ASQ, ASQActionType } from './DashletsASQ.types';

interface QueryActionModalProps {
    actionType: ASQActionType;
    onSubmit: (dataObject: any) => void;
    onCancel: () => void;
    modalFields: ModalFieldType;
    asq: ASQ;
    submitDisable: boolean;
    setModalFields: React.Dispatch<React.SetStateAction<ModalFieldType>>;
}

export const QueryActionModal = ({
    actionType,
    onSubmit,
    onCancel,
    modalFields,
    submitDisable,
    setModalFields,
    asq
}: QueryActionModalProps) => {
    const [, startTransition] = useTransition();
    const selectedRegion = useSelector(selectSelectedRegion);

    const submitButtonClick = () => {
        if (
            !validateFormFields(
                0,
                setModalFields,
                modalFields,
                actionFormMetaData
            )
        ) {
            const keysObject = Object.keys(modalFields);
            const dataObject = keysObject.reduce(
                (totalObject: any, currentValue) => {
                    const modalFieldValue = modalFields[currentValue].value;
                    totalObject[ASQ_QUERY_MAPPING[currentValue]] =
                        modalFieldValue;

                    return totalObject;
                },
                {}
            );
            onSubmit({ ...dataObject, regionId: selectedRegion });
        }
    };

    const getModalTitle = () => {
        if (actionType === 'add') return ADD_QUERY;
        if (actionType === 'delete') return DELETE_QUERY;
        if (actionType === 'edit') return EDIT_QUERY;
        return '';
    };

    return (
        <Modal
            className={actionType !== 'delete' ? 'wide' : ''}
            isDisabledSubmitBtn={submitDisable}
            isModalOpen
            onCancel={onCancel}
            onSubmit={submitButtonClick}
            submitBtnLabel={actionType === 'delete' ? DELETE : SUBMIT}
            title={getModalTitle()}
        >
            {actionType === 'delete' ? (
                <WarningModalContainer
                    text={QUERY_DELETE_WARNING.replace('%s', asq.title)}
                />
            ) : (
                <div
                    style={{
                        height: '160px'
                    }}
                >
                    {actionFormMetaData[0].map(
                        (
                            { labelName, type, inputType, placeHolder },
                            index
                        ) => (
                            <ModalField
                                key={index}
                                index={index}
                                inputType={inputType}
                                {...modalFields[labelName]}
                                labelName={labelName}
                                onChange={fieldValue =>
                                    onFieldChangeHandler(
                                        fieldValue,
                                        labelName,
                                        type,
                                        actionFormMetaData,
                                        setModalFields,
                                        0,
                                        modalFields,
                                        startTransition
                                    )
                                }
                                placeHolder={placeHolder}
                                type={type}
                            />
                        )
                    )}
                </div>
            )}
        </Modal>
    );
};
