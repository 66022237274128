const ArmisLogo = () => (
    <svg
        data-component="ArmisLogo"
        data-element="svg"
        fill="white"
        height={55}
        viewBox="0 0 76 32"
        width={134}
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M1.07,23.16,16,1.12a.75.75,0,0,1,1.33,0l5.52,8.23,1.84,2.73H21.48a2.49,2.49,0,0,0-1.83.93L13.06,23.16Z"
            data-element="path"
        />
        <path
            d="M15.79,30.59l5.87-11.71a.37.37,0,0,1,.51-.16.35.35,0,0,
            1,.16.16L28.2,30.59a.33.33,0,0,1,0,.32.31.31,0,0,
            1-.29.14H26.39a.77.77,0,0,1-.44-.14.71.71,0,0,
            1-.31-.33l-.5-1a1,1,0,0,0-.32-.32.71.71,0,0,0-.43-.13h-4.8a.87.87,
            0,0,0-.44.13.76.76,0,0,0-.3.34l-.49,1a.82.82,
            0,0,1-.31.33.77.77,0,0,1-.44.14H16.08a.33.33,0,0,1-.3-.14A.33.33,0,
            0,1,15.79,30.59ZM20.74,27h2.5a.3.3,0,0,
            0,.3-.14.35.35,0,0,0,0-.33l-1.32-2.79c-.05-.13-.13-.2-.22-.2s-.16.07-.22.2l-1.33,
            2.79a.34.34,0,0,0,0,.34A.32.32,0,0,0,20.74,27Z"
            data-element="path"
        />
        <path
            d="M31.54,31.05H30.2a.52.52,0,0,1-.52-.52h0V19.2a.5.5,0,0,1,.51-.52H35.3a4.74,
            4.74,0,0,1,1.75.3,4.53,4.53,0,0,1,1.33.88,4.31,4.31,0,0,1,.51.62,4.17,4.17,0,0,1,.37.73,4.64,4.64,0,0,
            1,.3,1.73,4.2,4.2,0,0,1-.69,2.45,4,4,0,0,1-1.4,1.28.47.47,0,0,0-.24.28.38.38,0,0,0,.09.35c.41.56.81,
            1.09,1.19,1.59s.77,1,1.17,1.6a.38.38,0,0,1,.08.37.27.27,0,0,1-.28.19H37.77a.89.89,0,0,
            1-.45-.12,1.07,1.07,0,0,1-.38-.29c-.19-.26-.38-.51-.56-.77l-.57-.76L34.7,27.53a1,1,0,0,
            0-.38-.3.89.89,0,0,0-.45-.12h-1.3a.52.52,0,0,0-.53.52h0v2.9a.52.52,0,0,1-.14.37A.51.51,0,0,1,31.54,
            31.05Zm1-6H35.3a1.85,1.85,0,0,0,.66-.13,1.71,1.71,0,0,0,.63-.39,2.35,2.35,0,0,0,.44-.64,2.2,2.2,0,0,0,.17-.91,
            3.5,3.5,0,0,0-.07-.65,2.42,2.42,0,0,0-.1-.36l-.15-.35A1.76,1.76,0,0,0,36.3,21a1.92,1.92,0,0,0-1-.24H32.57a.52.52,0,0,
            0-.53.52h0v3.19a.52.52,0,0,0,.52.52Z"
            data-element="path"
        />
        <path
            d="M55.27,19V30.53a.49.49,0,0,1-.15.37.51.51,0,0,1-.35.15H53.43a.52.52,0,0,
            1-.52-.52h0V25.32c0-.15,0-.24-.1-.26s-.13,0-.22.14L49.37,29a.4.4,0,0,1-.56.09l-.1-.09L45.52,25.2c-.09-.11-.17-.16-.24-.13s-.1.1-.1.25v5.21a.52.52,0,0,
            1-.51.52H43.34a.53.53,0,0,1-.53-.52h0V19a.24.24,0,0,1,.2-.25h0a.34.34,0,0,1,.34.13l5.34,6.6a.47.47,0,0,0,.64,0l0,0,5.36-6.6a.34.34,0,0,
            1,.34-.13.21.21,0,0,1,.2.25Z"
            data-element="path"
        />
        <path
            d="M61,31.05H59.63a.51.51,0,0,1-.36-.15.52.52,0,0,1-.14-.37V19.22a.53.53,0,0,1,.14-.38.51.51,0,0,1,.36-.15H61a.52.52,0,0,1,.52.52h0V30.53a.51.51,0,0,1-.51.52Z"
            data-element="path"
        />
        <path
            d="M65.05,29l.71-1a.45.45,0,0,1,.33-.22.47.47,0,0,1,.38.08,14.31,14.31,0,0,0,1.49.81,4.7,4.7,0,0,0,2.08.48,3.2,3.2,0,
            0,0,1-.16,2.15,2.15,0,0,0,.74-.37,1.76,1.76,0,0,0,.46-.52,1.19,1.19,0,0,0,.15-.58,1,1,0,0,0-.24-.64,
            1.85,1.85,0,0,0-.61-.42,2.74,2.74,0,0,0-.4-.15l-.49-.14L70.15,26l-.55-.1-.4-.08-.46-.09a4.82,4.82,0,0,1-1-.25,4.93,4.93,0,0,
            1-1-.42,3.92,3.92,0,0,1-.91-.66,2.85,2.85,0,0,1-.66-.95,3.35,3.35,0,0,1-.23-1.29,3.52,3.52,0,0,1,.43-1.8,3.59,3.59,0,0,
            1,1.12-1.14A5.17,5.17,0,0,1,68,18.66a8.1,8.1,0,0,1,1.62-.17,9.19,9.19,0,0,1,1.39.1,5.43,5.43,0,0,1,.58.13l.55.16a5,5,0,0,
            1,.53.23c.17.08.33.17.49.26l.32.2a2.18,2.18,0,0,0,.34.2.57.57,0,0,1,.22.34.54.54,0,0,1-.1.39l-.71,1a.62.62,0,0,1-.35.22.57.57,0,0,
            1-.4-.09A6.41,6.41,0,0,0,71.19,21a4.33,4.33,0,0,0-1.71-.32h-.33l-.36.05a3.6,3.6,0,0,0-.74.26,2,2,0,0,0-.32.18,2,2,0,0,0-.26.24,
            1,1,0,0,0-.22.64.87.87,0,0,0,.27.63,2.25,2.25,0,0,0,.7.42,3.26,3.26,0,0,0,.44.15l.49.14.49.12a3.89,3.89,0,0,1,.49.12l.38.08a3.73,
            3.73,0,0,0,.44.08l.49.1a4.84,4.84,0,0,1,.51.14l.28.09.28.1a4.58,4.58,0,0,1,.46.22,3.35,3.35,0,0,1,.45.27,2.2,2.2,0,0,1,.25.17l.21.2a2.78,
            2.78,0,0,1,.64,1,3.44,3.44,0,0,1,.25,1.42,3.81,3.81,0,0,1-.33,1.67,3,3,0,0,1-1,1.17,4.1,4.1,0,0,1-1.49.71,7.63,7.63,0,0,1-1.91.24,8.82,
            8.82,0,0,1-1.7-.16A5.28,5.28,0,0,1,67,30.74a4.41,4.41,0,0,1-.59-.26,6.12,6.12,0,0,1-.56-.31L65.54,30l-.35-.25a.43.43,0,0,1-.22-.32A.6.6,0,0,1,65.05,29Z"
            data-element="path"
        />
    </svg>
);

export default ArmisLogo;
