export const Validators = {
    validateNotEmpty: (value: string) => value.trim().length !== 0,
    validateMinLength: (value: string, minLen: number) =>
        value.trim().length >= minLen,
    validateMaxLength: (value: string, maxLen: number) =>
        value.trim().length <= maxLen,
    validateMinValue: (value: number, minValue: number) => value < minValue,
    validateMaxValue: (value: number, maxValue: number) => value > maxValue,
    validateContainLowerCase: (value: string) => /[a-z]/.test(value),
    validateContainUpperCase: (value: string) => /[A-Z]/.test(value),
    validateContainNumber: (value: string) => /[0-9]/.test(value),
    validateContainSpecialChars: (value: string) =>
        /[ `!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?~]/.test(value),
    validateAgainstFormat: (value: string, regex: string) =>
        new RegExp(regex).test(value),
    validateNumberRange: (
        minValue: number,
        maxValue: number,
        value: number
    ) => {
        if (Number.isNaN(value) || value < minValue || value > maxValue)
            return false;
        return true;
    },
    validateUrl: (value: string) => {
        try {
            const tenantUrl = new URL(value);
            // eslint-disable-next-line no-script-url
            return tenantUrl.protocol !== 'javascript:';
        } catch (err) {
            return false;
        }
    }
};
