import React from 'react';

import { TextBox } from '@armis/armis-ui-library';
import { Grid, InputAdornment } from '@mui/material';
import { IoIosClose } from 'react-icons/io';
import { OPTIONAL } from 'src/constants/LabelText';

import { OptionalSpanStyle, StyledDiv, StyledSpan } from './EditField.style';
import { EditFieldProps } from './EditField.types';

export const EditField = ({
    key,
    id,
    type,
    disable,
    label,
    value,
    onChange,
    gridSize,
    error = false,
    helperText = '',
    optional = false
}: EditFieldProps) => (
    <Grid item xs={gridSize}>
        <StyledDiv>
            <StyledSpan>
                {label}{' '}
                {optional ? (
                    <OptionalSpanStyle>{OPTIONAL}</OptionalSpanStyle>
                ) : null}
            </StyledSpan>
            <TextBox
                key={key}
                autoComplete="off"
                disabled={disable}
                error={error}
                helperText={helperText}
                id={id}
                InputProps={{
                    endAdornment: !disable && value && (
                        <InputAdornment
                            onClick={() => onChange('')}
                            position="end"
                            variant="outlined"
                        >
                            <IoIosClose />
                        </InputAdornment>
                    )
                }}
                onChange={e => onChange(e.target.value)}
                type={type}
                value={value}
            />
        </StyledDiv>
    </Grid>
);
