import React from 'react';

import { WarningTri } from '@armis/armis-ui-library';
import { Box } from '@mui/material';

import { StyledContainer, StyledText } from './WarningModalContainer.style';

export const WarningModalContainer = ({ text }: { text: string }) => (
    <StyledContainer>
        <Box className="control svg-symbol-button" sx={{ height: '80px' }}>
            <WarningTri height="60" width="60" />
        </Box>
        <StyledText>{text}</StyledText>
    </StyledContainer>
);
