import React, { useEffect, useState } from 'react';

import { TextBox } from '@armis/armis-ui-library';
import { InputAdornment, Typography } from '@mui/material';
import { AxiosError } from 'axios';
import { IoIosClose } from 'react-icons/io';
import {
    CONFIRM_ACTION,
    CONFIRM_ACTION_MODAL_TEXT_1,
    CONFIRM_ACTION_MODAL_TEXT_2,
    OK,
    PASSWORD_PLACEHOLDER
} from 'src/constants/LabelText';
import { Validators } from 'src/helpers/Validators';
import { Modal } from 'src/pages/components/Modal';
import { verifyPassword } from 'src/services/api.service';
import { VerifyPasswordPayload } from 'src/types/APIPayloadTypes';
import { ErrorResponse } from 'src/types/APIResponseTypes';

import { ConfirmActionProps } from './ConfirmAction.types';

export const ConfirmAction = ({
    password,
    setPassword,
    onClickCancel,
    onSubmitSuccess,
    setIsLoading
}: ConfirmActionProps) => {
    const [errorMsg, setErrorMsg] = useState('');
    const [isDisabledSubmitBtn, setIsDisabledSubmitBtn] = useState(true);

    useEffect(() => {
        setIsDisabledSubmitBtn(!Validators.validateNotEmpty(password));
    }, [password]);

    const onClickSubmitHandler = () => {
        const data: VerifyPasswordPayload = {
            cup: password
        };

        setIsLoading(true);

        verifyPassword(data)
            .then(() => {
                setErrorMsg('');
                onSubmitSuccess();
            })
            .catch((err: AxiosError<ErrorResponse>) => {
                setIsLoading(false);
                if (err.response) {
                    setErrorMsg(err.response?.data?.message || '');
                } else {
                    setErrorMsg(err.message);
                }
            });
    };

    return (
        <Modal
            isDisabledSubmitBtn={isDisabledSubmitBtn}
            isModalOpen
            onCancel={onClickCancel}
            onSubmit={onClickSubmitHandler}
            submitBtnLabel={OK}
            title={CONFIRM_ACTION}
        >
            <Typography gutterBottom sx={{ mb: '20px' }}>
                {CONFIRM_ACTION_MODAL_TEXT_1}
            </Typography>
            <Typography gutterBottom sx={{ mb: '20px' }}>
                {CONFIRM_ACTION_MODAL_TEXT_2}
            </Typography>
            <TextBox
                error={!!errorMsg}
                fullWidth
                helperText={errorMsg}
                hiddenLabel
                id="password-field"
                InputProps={{
                    endAdornment: password && (
                        <InputAdornment
                            onClick={() => setPassword('')}
                            position="end"
                            variant="outlined"
                        >
                            <IoIosClose />
                        </InputAdornment>
                    )
                }}
                onChange={e => {
                    setPassword(e.target.value);
                }}
                onKeyDown={e => {
                    if (e.key === ' ') e.preventDefault();
                }}
                placeholder={PASSWORD_PLACEHOLDER}
                size="small"
                type="password"
                value={password}
                variant="outlined"
            />
        </Modal>
    );
};
