import React, { memo } from 'react';

import { Helmet } from 'react-helmet-async';
import { ARMIS } from 'src/constants/LabelText';

import { StyledHeader, StyledTitle, StyledSpan } from './Header.style';
import { HeaderProps } from './Header.types';

const HeaderComponent = ({ title = '', children }: HeaderProps) => (
    <>
        {!!title.trim().length && (
            <Helmet>
                <title>{`${ARMIS} | ${title}`}</title>
            </Helmet>
        )}
        <StyledHeader>
            <StyledTitle>
                {title && <StyledSpan>{title}</StyledSpan>}
                {children}
            </StyledTitle>
        </StyledHeader>
    </>
);

export const Header = memo(HeaderComponent);
