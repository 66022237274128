import { Routes, Route, Navigate } from 'react-router-dom';
import {
    HOME,
    LOGIN,
    MY_PROFILE,
    ROLE_MANAGEMENT,
    SETTINGS,
    AUDIT_LOG,
    PARTNERS,
    USER_MANAGEMENT,
    DASHLETS_ASQ
} from 'src/constants/RouteConstants';
import { ProtectedRoute } from 'src/pages/common/ProtectedRoute';
import { AggregatedView } from 'src/pages/containers/AggregatedView/AggregatedView';
import { TenantAggregationView } from 'src/pages/containers/AggregatedView/TenantAggregationView';
import { AuditLog } from 'src/pages/containers/AuditLog';
import { DashletsASQ } from 'src/pages/containers/DashletsASQ/DashletsASQ';
import { Login } from 'src/pages/containers/Login';
import { Partners } from 'src/pages/containers/Partners';
import { PartnerTenants } from 'src/pages/containers/PartnerTenants/PartnerTenants';
import { Profile } from 'src/pages/containers/Profile';
import { RoleManagement } from 'src/pages/containers/RoleManagement';
import { UserManagement } from 'src/pages/containers/UserManagement/UserManagement';

const AppRoutes = () => (
    <Routes>
        <Route element={<Login />} path={LOGIN} />
        <Route element={<ProtectedRoute />}>
            <Route element={<Profile />} path={MY_PROFILE} />
            <Route path={SETTINGS}>
                <Route element={<RoleManagement />} path={ROLE_MANAGEMENT} />
                <Route element={<UserManagement />} path={USER_MANAGEMENT} />
                <Route element={<AuditLog />} path={AUDIT_LOG} />
                <Route element={<DashletsASQ />} path={DASHLETS_ASQ} />
            </Route>
            <Route path={PARTNERS}>
                <Route element={<Partners />} index />
                <Route element={<PartnerTenants />} path=":partnerId" />
            </Route>
            <Route path={HOME}>
                <Route element={<AggregatedView />} index />
                <Route element={<TenantAggregationView />} path=":partnerId" />
            </Route>
        </Route>
        <Route element={<Navigate to={HOME} />} path="*" />
    </Routes>
);

export default AppRoutes;
