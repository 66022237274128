import { constants } from '@armis/armis-ui-library';
import { styled } from '@mui/material';

export const StyledTitle = styled('span')`
    font-size: 2rem;
`;

export const StyledOption = styled('div')`
    line-height: 28px;
    padding: 4px 10px;
`;
export const StyledHeaderContainer = styled('div')`
    border: 1px solid
        ${props =>
            props.theme.palette.mode === 'dark'
                ? constants.COLOR_GENERAL_28
                : constants.COLOR_GENERAL_52};
    background: ${props =>
        props.theme.palette.mode === 'dark'
            ? constants.COLOR_GENERAL_30
            : constants.COLOR_WHITE};
    color: ${props =>
        props.theme.palette.mode === 'dark'
            ? constants.COLOR_GENERAL_25
            : constants.COLOR_GENERAL_22};
    border-bottom: none;
`;
