import { constants } from '@armis/armis-ui-library';
import { styled } from '@mui/material';

export const StyledHeader = styled('div')`
    align-items: center;
    // display: flex;
    height: 50px;
    padding: 15px 64px 10px 64px;
    box-sizing: content-box;

    @media (max-width: 1360px) {
        padding: 16px 32px 16px 32px;
    }
`;

export const StyledTitle = styled('div')`
    font-family: ${constants.PROXIMANOVA_W08_BOLD};
    font-size: 30px;
    line-height: 38px;
    color: ${props =>
        props.theme.palette.mode === 'dark'
            ? constants.COLOR_GENERAL_25
            : constants.COLOR_GENERAL_22};
    display: flex;
    justify-content: space-between;
    align-items: center;
`;

export const StyledSpan = styled('span')`
    // margin-right: 5px;
    word-spacing: 3px;
`;
