import { styled } from '@mui/material/styles';

export const StyledConsole = styled('div')`
    display: flex;
    height: 100%;
    color-scheme: ${props =>
        props.theme.palette.mode === 'dark' ? 'dark' : 'normal'};
`;

export const Content = styled('div')`
    flex: 1;
    background-color: ${props =>
        props.theme.palette.mode === 'dark' ? '#15111D' : '#FAFAFC'};
    display: flex;
    flex-direction: column;
    max-width: calc(100% - 90px);
`;
