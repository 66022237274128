import { constants } from '@armis/armis-ui-library';
import { Avatar, styled } from '@mui/material';

export const ProfileContainer = styled('div')`
    display: flex;
    flex-direction: column;
    box-sizing: content-box;
    color: ${props =>
        props.theme.palette.mode === 'dark'
            ? constants.COLOR_GENERAL_25
            : constants.COLOR_GENERAL_22};
`;

export const Control = styled('div')`
    padding: 30px 64px;
    font-size: 16px;
    display: flex;

    @media (max-width: 1360px) {
        padding: 16px 32px;
    }
`;

export const Area = styled('div')`
    flex: 1;
`;

export const Content = styled('form')`
    font-size: 16px;
    display: flex;
    padding-top: 24px;
    padding-right: 30px;
    padding-bottom: 160px;
`;

export const ImageContainer = styled('div')`
    margin: 0px 14px;
    position: relative;
    display: inline-block;
    width: 150px;
    height: 150px;
    vertical-align: top;
    margin-right: 30px;
    border-radius: 50%;
    overflow: hidden;
`;

export const StyledAvatar = styled(Avatar)`
    width: 150px;
    height: 150px;
    line-height: 150px;
    font-size: 75px;
    background-color: #372564;
    color: #fff;
    text-align: center;
`;

export const FieldsContainer = styled('table')`
    display: inline-table;
    padding-left: 30px;
`;

export const ProfileHeaderContainer = styled('h3')`
    margin: 0;
    margin-bottom: 13px;
`;
