import { constants } from '@armis/armis-ui-library';
import { styled } from '@mui/material';
import { Link } from 'react-router-dom';

const linkStyle = `
    font-family: ${constants.PROXIMANOVA_W08_SEMIBOLD};
    font-size: 1.4rem;
    letter-spacing: 0.4px;
    text-decoration: none;
    cursor: pointer;
    
    &:hover {
        text-decoration: underline;
    }`;

export const StyledAnchorTag = styled('a')`
    ${linkStyle}
    color: ${props =>
        props.theme.palette.mode === 'dark' ? '#9DD2FF' : '#3569EB'};
`;

export const StyledLink = styled(Link)`
    ${linkStyle}
    color: ${props =>
        props.theme.palette.mode === 'dark' ? '#9DD2FF' : '#3569EB'};
`;

export const StyledLoaderContainer = styled('div')<{
    isHidden: boolean;
}>(({ isHidden }) => ({
    visibility: isHidden ? 'hidden' : 'visible',
    height: '100%',
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column'
}));
