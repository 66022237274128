import { styled, Container } from '@mui/material';

export const StyledContainer = styled(Container)(() => ({
    display: 'flex',
    height: '100vh',
    marginLeft: '155px'
}));

export const StyledDiv = styled('div')`
    display: flex;
    flex-direction: column;
    max-width: 500px;
    margin-top: 100px;
    box-sizing: content-box;

    & .login-video {
        width: 100vw;
        height: 100vh;
        object-fit: cover;
        position: fixed;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        z-index: -1;
    }

    & .login-header {
        font-size: 40px;
        color: white;
        margin-bottom: 30px;
        margin-top: 18px;
        line-height: 40px;
        @media (max-width: 1440px) {
            font-size: 30px;
            line-height: 30px;
        }
    }
    & .login-sub-header {
        font-size: 20px;
        color: white;
        margin-bottom: 30px;
        line-height: 30px;
        margin-top: -4px;
        @media (max-width: 1440px) {
            font-size: 15px;
        }
    }
    & .content {
        width: 300px;
        padding-top: 30px;
        & .header {
            font-family: var(--chakra-fonts-bold);
            color: #fff;
            font-size: 16px;
            letter-spacing: 0.4px;
            margin-top: -2px;
        }
    }

    & .logo-resize {
        @media (min-width: 1530px) {
            width: 400px;
        }
        @media (min-width: 1340px) and (max-width: 1529px) {
            width: 300px;
        }
    }
`;

export const StyledImage = styled('img')`
    margin-bottom: 100px;
`;

export const StyledImageAchor = styled('a')`
    display: flex;
    justify-ceontent: center;
`;

export const ImageContainer = styled('div')`
    display: flex;
    flex: 1;
    justify-content: center;
    padding-right: 30px;
    width: 260px;
    border-right: 1px solid #e8e8e8;
`;

export const InputContainer = styled('div')`
    margin-bottom: 20px;
    margin-top: 20px;
`;

export const ButtonContainer = styled('div')`
    display: flex;
    align-items: baseline;
    margin-top: 20px;
`;
