import React from 'react';

import { constants, IconPencil, IconButton } from '@armis/armis-ui-library';
import { Typography } from '@mui/material';
import { StyledPageTitle } from 'src/pages/containers/Partners/ReviewPageTitle/ReviewPageTitle.style';

interface ReviewPageTitleProps {
    pageTitle: string;
    navigateFunction: () => void;
    hideIcon?: boolean;
}

export const ReviewPageTitle = ({
    pageTitle,
    navigateFunction,
    hideIcon
}: ReviewPageTitleProps) => (
    <StyledPageTitle>
        <Typography
            sx={{
                fontFamily: constants.PROXIMANOVA_W08_SEMIBOLD
            }}
            variant="h4"
        >
            {pageTitle}
        </Typography>
        {!hideIcon && (
            <IconButton onClick={navigateFunction}>
                <IconPencil height={16} />
            </IconButton>
        )}
    </StyledPageTitle>
);
