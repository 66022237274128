import { TextBox, constants } from '@armis/armis-ui-library';
import { styled } from '@mui/material';

export const StyledTextBox = styled(TextBox)`
    & .MuiInputBase-input {
        font-size: 13px;
    }
    .MuiInputBase-root {
        padding-right: 0px;
    }
    .Mui-disabled fieldset {
        border-color: ${constants.COLOR_GENERAL_6} !important;
    }
    .clear {
        width: 16px;
    }
    .icon-search {
        fill: ${constants.COLOR_GENERAL_5};
    }
`;

export const SearchBarIconContainer = styled('div')`
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 36px;
    height: 36px;
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
`;
