import {
    IconButton,
    ShowSkeleton,
    MenuIcon,
    ColumnsFilter
} from '@armis/armis-ui-library';
import { SELECTED } from 'src/constants/LabelText';

import { StyledHeaderContainer, StyledTitle } from './TableHeader.style';
import { TableHeaderProps } from './TableHeader.types';

export const TableHeader = ({
    loading,
    title,
    selectedCount = 0,
    childrenLeft,
    childrenRight,
    onColumnMenuClick,
    setAnchorEl
}: TableHeaderProps) => (
    <StyledHeaderContainer className="table-header">
        <ShowSkeleton
            content={
                <StyledTitle data-testid="table-title">
                    <span>{title}</span>
                </StyledTitle>
            }
            loading={loading}
        />
        {selectedCount > 0 && (
            <span className="selected-counter">
                ({selectedCount} {SELECTED})
            </span>
        )}
        <span className="spacer" />
        <div className="control actions">
            {childrenLeft}
            {setAnchorEl && (
                <IconButton
                    disabled={!(selectedCount > 0)}
                    onClick={event => setAnchorEl(event.currentTarget)}
                >
                    <MenuIcon />
                </IconButton>
            )}
            {childrenRight}
        </div>
        <IconButton disabled={loading} onClick={onColumnMenuClick}>
            <ColumnsFilter />
        </IconButton>
    </StyledHeaderContainer>
);
