import React from 'react';

import { Loader, SideNavbar } from '@armis/armis-ui-library';
import { cloneDeep } from 'lodash';
import { useSelector } from 'react-redux';
import { Navigate, Outlet, useLocation } from 'react-router-dom';
import {
    defaultRouteConstants,
    HOME,
    LOGIN,
    sideNavbarProps
} from 'src/constants/RouteConstants';
import { urlFilter } from 'src/helpers/ColumnsConfig';
import { useAuth } from 'src/hooks/useAuth';
import { TopBar } from 'src/pages/components/TopBar';
import { selectUser } from 'src/store/slices/userSlice';
import { User } from 'src/types/APIResponseTypes';

import { Content, StyledConsole } from './ProtectedRoute.style';
import { ProtectedRouteProps } from './ProtectedRoute.types';

export const ProtectedRoute = ({
    redirectPath = LOGIN
}: ProtectedRouteProps) => {
    const isAuth = useAuth();
    const { pathname } = useLocation();
    const currentUser = useSelector(selectUser);

    if (isAuth === null)
        return (
            <Loader fade={false} loaderType={{ name: 'dots' }} show>
                <div className="dots" />
            </Loader>
        );
    if (isAuth && currentUser) {
        const filteredSideNavbar = cloneDeep(sideNavbarProps);
        const allRoutes: string[] = [];
        const allowedRoutes: string[] = [];

        /*
            Redirect the page using relayState fetched from localStorage.
        */
        const relayState = localStorage.getItem('relayState');
        localStorage.removeItem('relayState');
        if (pathname === HOME && relayState) {
            return (
                <Navigate
                    replace
                    to={{
                        pathname: relayState
                    }}
                />
            );
        }

        /*
            Check for the permissions required to display every route.
         */
        const hasRequiredPermissions = (route: {
            resource?: string;
            requiredPermission?: string[];
            isSSOConfigRequired?: boolean;
        }) => {
            let shouldAllow = true;
            if (route.isSSOConfigRequired) {
                shouldAllow = !!currentUser.isSsoConfigured;
            }
            if (route.requiredPermission) {
                const resource = (currentUser as User).resources.find(
                    r => r.name === route.resource
                );
                shouldAllow =
                    shouldAllow &&
                    route.requiredPermission.every(permission =>
                        resource?.privilegeNames.includes(permission)
                    );
            }

            return shouldAllow;
        };

        filteredSideNavbar.items.forEach(parentRoute => {
            if (parentRoute.routePath) allRoutes.push(parentRoute.routePath);
            parentRoute.subMenuItems?.forEach(pathSection => {
                pathSection.itemContent = pathSection.itemContent.filter(
                    route => {
                        allRoutes.push(route.routePath);
                        return hasRequiredPermissions(route);
                    }
                );
            });

            parentRoute.subMenuItems = parentRoute.subMenuItems?.filter(
                pathSection => pathSection.itemContent.length
            );
        });

        /*
            Parent route can have two options, either it is a route with permissions or it has a sub-routes list.
        */
        filteredSideNavbar.items = filteredSideNavbar.items.filter(
            parentRoute => {
                let shoulAllow = true;
                if (parentRoute.routePath) {
                    shoulAllow = hasRequiredPermissions(parentRoute);
                } else if (parentRoute.subMenuItems) {
                    shoulAllow = !!parentRoute.subMenuItems.length;
                }
                return shoulAllow;
            }
        );

        filteredSideNavbar.items.forEach(parentRoute => {
            if (parentRoute.routePath) {
                allowedRoutes.push(parentRoute.routePath);
            }

            parentRoute.subMenuItems?.forEach(pathSection => {
                pathSection.itemContent.forEach(route => {
                    allowedRoutes.push(route.routePath);
                });
            });
        });

        /*
            Restrict routes if typed directly on the search bar.
         */
        if (
            allRoutes.includes(pathname) &&
            allowedRoutes.length &&
            !allowedRoutes.includes(pathname)
        )
            return <Navigate replace to={allowedRoutes[0]} />;

        /*
            Redirect to default child route if a parent route is entered in URL.
        */
        if (Object.keys(defaultRouteConstants).includes(urlFilter(pathname)))
            return (
                <Navigate
                    replace
                    to={defaultRouteConstants[urlFilter(pathname)]}
                />
            );

        return (
            <StyledConsole>
                <SideNavbar sideNavbar={filteredSideNavbar} />
                <Content>
                    <TopBar />
                    <Outlet />
                </Content>
            </StyledConsole>
        );
    }

    return <Navigate replace to={redirectPath} />;
};
