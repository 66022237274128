import { styled } from '@mui/material';

export const StyledContainer = styled('div')`
    display: flex;
    flex-direction: row;
    align-items: center;
`;
export const StyledText = styled('div')`
    overflow: auto;
    margin-left: 30px;
    font-size: 1.4rem;
    white-space: pre-line;
`;
