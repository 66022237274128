import { constants } from '@armis/armis-ui-library';
import { styled } from '@mui/material';

export const ButtonContainer = styled('div')`
    padding: 10px 20px 0 20px;
    display: flex;
    justify-content: space-between;

    & .queries-count {
        font-family: ${constants.PROXIMANOVA_W08_SEMIBOLD};
        font-size: 1.8rem;
        color: ${props =>
            props.theme.palette.mode === 'dark'
                ? constants.COLOR_GENERAL_25
                : constants.COLOR_GENERAL_22};
    }
`;

export const StyledASQContainer = styled('div')`
    display: flex;
    flex-direction: column;
    row-gap: 10px;
    margin-top: 10px;
    padding: 10px 20px;
    height: calc(100vh - 100px);
    overflow-y: auto;
`;

export const StyledASQItem = styled('div')`
    display: flex;
    column-gap: 5px;
    padding: 20px;
    background-color: ${props =>
        props.theme.palette.mode === 'dark'
            ? constants.COLOR_GENERAL_30
            : constants.COLOR_GENERAL_8};
    border-radius: 4px;
    justify-content: space-between;

    & .right {
        display: flex;
        flex-direction: row;
        & .icon-pencil {
            height: 16px;
            min-width: 32px;
        }
    }

    & .left {
        display: flex;
        flex-direction: column;
        row-gap: 5px;
        width: 95%;

        & .title {
            color: ${props =>
                props.theme.palette.mode === 'dark'
                    ? constants.COLOR_GENERAL_25
                    : constants.COLOR_GENERAL_22};
        }

        & .query {
            color: ${props =>
                props.theme.palette.mode === 'dark'
                    ? constants.COLOR_GENERAL_52
                    : constants.COLOR_GENERAL_4};
            font-family: ${constants.PROXIMANOVA_W08_THIN_REGULAR};
        }
    }
`;

export const StyledSectionParagraph = styled('div')`
    font-family: ${constants.PROXIMANOVA_W08_REGULAR};
    font-size: 16px;
    padding: 15px 64px 10px 64px;
    color: ${props =>
        props.theme.palette.mode === 'dark'
            ? constants.COLOR_GENERAL_25
            : constants.COLOR_GENERAL_22};
    @media (max-width: 1360px) {
        padding: 16px 32px 16px 32px;
    }
`;
