import React from 'react';

import { IconDelete, IconPencil, IconButton } from '@armis/armis-ui-library';
import { Privileges, Resources } from 'src/constants/CommonConstants';
import { DELETE, EDIT } from 'src/constants/LabelText';
import { ProtectedAction } from 'src/pages/common/ProtectedAction';

import { StyledASQItem } from './DashletsASQ.style';
import { ASQ, ASQActionType } from './DashletsASQ.types';

interface ASQItemProps extends Omit<ASQ, 'id'> {
    onClickAction: (actionType: ASQActionType) => void;
}

export const ASQItem = ({ title, asq, onClickAction }: ASQItemProps) => (
    <StyledASQItem>
        <div className="left">
            <div className="title"> {title} </div>
            <div className="query">{asq}</div>
        </div>
        <div className="right" style={{ marginTop: '5px' }}>
            <ProtectedAction
                hasPermissions={[Privileges.edit]}
                resource={Resources.settings}
            >
                <IconButton
                    aria-label={EDIT}
                    className="Icon-Hover-Effect"
                    onClick={e => {
                        e.currentTarget.blur();
                        onClickAction('edit');
                    }}
                >
                    <IconPencil />
                </IconButton>
            </ProtectedAction>
            <ProtectedAction
                hasPermissions={[Privileges.delete]}
                resource={Resources.settings}
            >
                <IconButton
                    aria-label={DELETE}
                    className="Icon-Hover-Effect"
                    onClick={e => {
                        e.currentTarget.blur();
                        onClickAction('delete');
                    }}
                >
                    <IconDelete />
                </IconButton>
            </ProtectedAction>
        </div>
    </StyledASQItem>
);
