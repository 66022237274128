import { constants } from '@armis/armis-ui-library';
import { MenuItem, styled } from '@mui/material';

export const StyledOption = styled('div')`
    line-height: 28px;
    padding: 4px 10px;
`;

interface StyledContainerProps {
    minHeight?: number;
}
export const StyledContainer = styled('div', {
    shouldForwardProp: prop => !['minHeight'].includes(prop as string)
})<StyledContainerProps>(({ minHeight }) => ({
    marginTop: '10pt',
    minHeight: minHeight ? `${minHeight}px` : 'unset'
}));

export const StyledField = styled('div')`
    padding-bottom: 10pt;
    .MuiFormLabel-root {
        font-family: Proxima Nova W08 Smbd;
        margin-bottom: 4px;
        font-size: 1.4rem;
        display: block;
    }
`;

export const StyledReview = styled('div')`
    border: 1px solid #eee;
    padding: 5px 10px;
    & > div:not(:last-child) {
        margin-bottom: 8px;
    }
    &:not(:last-child) {
        margin-bottom: 8px;
    }
`;

interface SectionProps {
    noBorder?: boolean;
    noMargin?: boolean;
    padding?: boolean;
}

export const StyledSection = styled('div', {
    shouldForwardProp: prop =>
        !['noBorder', 'noMargin', 'padding'].includes(prop as string)
})<SectionProps>(({ noBorder, noMargin, padding }) => ({
    borderBottom: !noBorder ? '1px solid #eee' : '',
    marginBottom: !noMargin ? '10px' : '',
    paddingBottom: padding ? '10px' : ''
}));

export const StyledCombinedField = styled('div')`
    display: flex;
    justify-content: space-between;
    & > div {
        width: 48%;
    }
`;

export const StyledBox = styled('div')`
    padding: 5px 0px;
    & > div:not(:last-child) {
        margin-bottom: 10px;
    }
`;

export const StyledMenuItem = styled(MenuItem)`
    color: ${props =>
        props.theme.palette.mode === 'dark'
            ? constants.COLOR_GENERAL_26
            : constants.COLOR_GENERAL_24};
`;
